import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { JoinUsComponent } from 'shared/components/join-us/join-us.component';
import { ReadMoreComponent } from 'shared/components/read-more/read-more.component';

@Component({
  standalone: true,
  imports: [CommonModule, JoinUsComponent, ReadMoreComponent, RouterLink],
  selector: 'app-faktura-vat',
  templateUrl: './faktura-vat.component.html',
  styleUrls: ['./faktura-vat.component.scss']
})
export class FakturaVatComponent {

}

import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'dexie';
import { finalize, take } from 'rxjs';
import { APIResponse, Utils } from 'fakturnia-shared';
import { NotificationData } from 'fakturnia-shared';
import { DataSynchronizerService } from 'shared/services/data-synchronization.service';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { ToastService } from 'shared/services/toast.service';
import { cloneDeep} from 'lodash'
import { Company } from 'fakturnia-shared';
import { ClientsApiService } from 'shared/services/api/clients.api.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SnackbarService } from 'shared/services/snackbar.service';

@Component({
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatButtonModule, MatIconModule, ReactiveFormsModule, FormsModule, MatDialogModule, MatTooltipModule],
  selector: 'app-create-or-update-client-dialog',
  templateUrl: './create-or-update-client-dialog.component.html',
  styleUrls: ['./create-or-update-client-dialog.component.scss']
})
export class CreateOrUpdateClientDialogComponent implements OnInit, OnDestroy{

  private _subscriptions: Subscription[] = []

  loading = false

  clientType: 'seller' | 'buyer' | 'receiver'
  client = null

  mode: 'create' | 'update' = 'create'

  iconFieldOn = 'tabler_outline:square-check'
  iconFieldOff = 'tabler_outline:square'

  fieldHiddenText = 'pole ukryte'
  fieldHiddenHint = 'To pole nie będzie wyświetlane na fakturze, zaznacz kwadrat, aby je wyświetlać.'
  showFieldText = 'Kliknij, aby wyświetlać to pole na fakturze'
  hideFieldText = 'Kliknij, aby nie wyświetlać tego pola na fakturze'
  
  // Field names must match
  form = new FormGroup({
    _id: new FormControl(''),
    type: new FormControl('company', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    phoneNumber: new FormControl(''),
    email: new FormControl(''),
    nip: new FormControl('', [Validators.required, Validators.minLength(2)]),
    regon: new FormControl(''),
    street: new FormControl('', [Validators.required]),
    postalCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    country: new FormControl(''),
    pesel: new FormControl(''),
    idNumber: new FormControl(''),
    bdo: new FormControl(''),
    krs: new FormControl(''),
    www: new FormControl(''),
    fax: new FormControl(''),
    description: new FormControl(''),
  })

  fieldsToShow = new Set<string>()

  constructor(
    private _clientsApiService:ClientsApiService,
    private _dialog: MatDialogRef<CreateOrUpdateClientDialogComponent>,
    private _dataSynchronizerService:DataSynchronizerService,
    private _synchronizationService:SynchronizationService,
    private _snackbarService:SnackbarService,
    private _cdr:ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: {clientType: 'seller' | 'buyer'  | 'receiver', client: Company}) {

     
      this.form.controls.type.setValue('company')
      if (typeof data == 'undefined') return
      if(data.client == null) return
      if(typeof data.client == 'undefined') return
      if(typeof data.clientType != 'undefined') {
        this.clientType = data.clientType
      } else this.clientType = 'buyer'

      // Set fields to show 
      if(Utils.isDefined(data,"client.fieldsToShow") && Array.isArray(data.client.fieldsToShow)) {
        this.fieldsToShow = new Set<string>(data.client.fieldsToShow)
      } else this.fieldsToShow = new Set<string>([])

      this.mode = data.client._id == null ? 'create' : 'update'
    
      Object.keys(this.form.controls).forEach(field=> {
        this.form.controls[field].setValue(data.client[field])
      })

      

  }

  public toggleFieldVisibility(field) {
    if(this.fieldsToShow.has(field)) this.fieldsToShow.delete(field)
    else this.fieldsToShow.add(field)
  }

  ngOnInit(): void {
    this._subscriptions.push(this.form.controls.type.valueChanges.subscribe({
      next: (data) => {

        if(data == 'person') {
          this.form.controls.name.setValidators(null)
          this.form.controls.name.updateValueAndValidity()

          this.form.controls.firstName.setValidators(Validators.required)
          this.form.controls.firstName.updateValueAndValidity()

          this.form.controls.lastName.setValidators(Validators.required)
          this.form.controls.lastName.updateValueAndValidity()
        } 
        else if( data == 'company') {

          this.form.controls.firstName.setValidators(null)
          this.form.controls.firstName.updateValueAndValidity()

          this.form.controls.lastName.setValidators(null)
          this.form.controls.lastName.updateValueAndValidity()

          this.form.controls.name.setValidators(Validators.required)
          this.form.controls.name.updateValueAndValidity()
        }
       
        this.form.updateValueAndValidity()
        // Trigger change detection
        this._cdr.detectChanges();
      }
    }))
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  saveChanges() {
    if(!this.form.valid) { return}
    if(this.loading == true) return
    if(this.clientType == 'seller') {

      this.loading = true
      let company:any = cloneDeep(this.form.value)
      company.fieldsToShow = Array.from(this.fieldsToShow)
      delete company._id
      this._dialog.close({
        success: true,
        client: company
      })
      this.loading = false
      return 
    }

    const requestData:any = cloneDeep(this.form.value)
    requestData.fieldsToShow = Array.from(this.fieldsToShow)

    if(this.mode == 'create') {
      this.loading = true
      this._clientsApiService.create(requestData)
      .pipe(finalize(() => {
        this.loading = false
      }))
      .pipe(take(1))
      .subscribe({
          next: (response:APIResponse) => this.processResponse(response)
      })
    }

    else if(this.mode == 'update') {
      this.loading = true
      const id = requestData._id
      delete requestData._id
      this._clientsApiService.update(id,requestData)
      .pipe(finalize(() => {
        this.loading = false
      }))
      .pipe(take(1))
      .subscribe({
          next: (response:APIResponse) => this.processResponse(response)
      })
    }
    
  }

  processResponse = (response:APIResponse) => {
    if(response.success != true) {
      this._snackbarService.showSnackBar(response.message,'error')
      return
    }

    this._dataSynchronizerService.next('clients',[response.data])
    this._synchronizationService.synchronize()
    this._dialog.close({
      success: true,
      client: response.data
    })

    this._snackbarService.showSnackBar('Dane klienta zostały zapisane.','error')
  }

  close() {
    this._dialog.close()
  }
}

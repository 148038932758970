import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { InvoicesService } from 'shared/services/invoices.service';
import { APIResponse, DbActivityLog, Utils } from 'fakturnia-shared';
import * as pdfMake from "pdfmake/build/pdfmake";
import { Title } from '@angular/platform-browser';
import { DialogService } from 'shared/services/dialog.service';
import { DocumentsService } from 'shared/services/documents.service';
import { DataSynchronizerService } from 'shared/services/data-synchronization.service';
import { Invoice } from 'fakturnia-shared';
import { DbDocument } from 'fakturnia-shared';
import { InvoiceGenerator } from 'fakturnia-shared';
import { DocumentsApiService } from 'shared/services/api/documents.api.service';
import { ActivityLogsService } from 'shared/services/activity-logs.service';
import { MediaWatcherService } from 'shared/services/media-watcher.service';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { ActivityLogsComponent } from 'shared/components/activity-logs/activity-logs.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, RouterLink, ActivityLogsComponent, NgxExtendedPdfViewerModule],
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit, OnDestroy {

  private readonly _subscriptions: Subscription[] = []

  private _id = null
  public invoice: Invoice | null = null
  public document: DbDocument | null= null
  public previewData: any = null
  public dataUrl: any = null
  
  public activityLogs:DbActivityLog[] = []
  public pdfPreviewHeight = '1300px'

  constructor(
    private _documentsApiService: DocumentsApiService,
    private _invoicesService: InvoicesService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialogService:DialogService,
    private _documentsService:DocumentsService,
    private _title:Title,
    private _dataSynchronizerService:DataSynchronizerService,
    private _activityLogsService:ActivityLogsService,
    private _mediaWatcherService:MediaWatcherService,
    private _synchronizationService:SynchronizationService

  ) {

    this._subscriptions.push(this._dataSynchronizerService.currentData.subscribe({
      next: (data)=>{
        if(data == null) return
        if(data.table == 'documents' || data.table == 'activityLogs') this._getInvoice()
      }
    }))

    this._subscriptions.push(this._route.params.subscribe({
      next: (params) => {

        if (!Utils.isDefined(params, "id")) {
          this._redirectToDocuments()
          return
        }

        this._id = params['id']
        this._getInvoice()

      }
    }))
  }
  ngOnInit(): void {
         // Subscribe to media changes
         this._subscriptions.push(
          this._mediaWatcherService.onMediaChange$
          .subscribe(({matchingAliases}) =>
          {
              // Check if the screen is small
              if(!matchingAliases.includes('sm')) {
                this.pdfPreviewHeight = '500px'
              } 
              else if(!matchingAliases.includes('md')) {
                this.pdfPreviewHeight = '750px'
              } 
              else if(!matchingAliases.includes('lg')) {
                this.pdfPreviewHeight = '900px'
              } 
              else this.pdfPreviewHeight = '1300px'
          })
         )
  }

  
  public showSendEmailDialog() {
    if(!this.invoice) return
    this._dialogService.showSendInvoiceByEmailDialog(this.invoice)
  }

  private _getInvoice() {

    if(!this._id) return
    
    this.invoice = this._invoicesService.getById(this._id)
    
    this.document = this._documentsService.getById(this._id)

    if (!this.invoice) {
      this._redirectToDocuments()
      return
    }

    this.activityLogs = this._activityLogsService.getInvoiceActivityLogs(this.invoice._id)
    console.log(this.activityLogs)
    this._title.setTitle(`Podgląd ${this.invoice.invoiceName} - Fakturnia.pl`)
    
    const docDefinition = InvoiceGenerator.createPDF(this.invoice.document, this.invoice, 0, "render")
    pdfMake.createPdf(<any>docDefinition).getBase64(dataUrl => this.dataUrl = dataUrl)
  }


  public markAsPaidOrTemplate(isPaid) {

    if(!this.invoice) return
    this._subscriptions.push(
      this._dialogService.confirmMarkAsPaidOrTemplateDialog(
        isPaid,
        [this.invoice?._id],
        isPaid ? "Oznacz jako wystawiono" : "Oznacz jako szkic",
        isPaid ? `Czy na pewno chcesz oznaczyć dokument ${this.invoice.invoiceName} jako wystawiony?` : `Czy na pewno chcesz oznaczyć dokument ${this.invoice.invoiceName} jako szkic?`
      )
      .subscribe()
    )
  }

 
  public print() {

    if(!this.invoice) return
    if(!Utils.isDefined(this.invoice,"document")) return

    const docDefinition = InvoiceGenerator.createPDF(this.invoice.document, this.invoice, 0, "render")

    pdfMake.createPdf(<any>docDefinition).print({})

    this._documentsApiService.markDocumentsAsPrinted(this.invoice._id).pipe(take(1)).subscribe({
      next: async (response:APIResponse) => {
        if(response.success != true) return
        await this._synchronizationService.synchronize()
      }
    })
  }

  public download() {

    if(!this.invoice) return
    if(!Utils.isDefined(this.invoice,"document")) return

    InvoiceGenerator.createPDF(this.invoice.document, this.invoice, 0, "download")

    this._documentsApiService.markDocumentsAsDownloaded(this.invoice._id).pipe(take(1)).subscribe({
      next: async (response:APIResponse) => {
        if(response.success != true) return
        await this._synchronizationService.synchronize()
      }
    })
  }

  /**
  Show confirmation before deleting invoice
  **/
  public confirmDeleteInvoice() {

    if(!this.invoice) return
    if(!Utils.isDefined(this.invoice,"_id")) return

    this._subscriptions.push(
      this._dialogService.confirmDeleteInvoiceDialog(this.invoice)
      .subscribe({
        next: (data) => {
          if(data != true) return
          this._redirectToDocuments()
        }
      }))
  }
 
  private _redirectToDocuments() {
    this._router.navigateByUrl('/konto/dokumenty')
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

}


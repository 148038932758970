import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[bankAccountFormat]'
})
export class BankAccountFormatDirective {

  constructor(private el: ElementRef) {}
  
  @HostListener('input', ['$event']) onInput(event: any) {
    let inputValue: string = event.target.value;

    // Remove non-numeric characters and extra spaces
    inputValue = inputValue.replace(/[^0-9]/g, '');

    // Format the input with a space after the first two characters and every 4 characters
    inputValue = this.formatBankAccountNumber(inputValue);

    // Truncate to a maximum of 32 characters without spaces (32 with spaces)
    inputValue = inputValue.substring(0, 32);

    // Update the input value
    this.el.nativeElement.value = inputValue;

  }

  private formatBankAccountNumber(value: string): string {
    // Add a space after the first two characters
    value = value.replace(/^(\d{2})/, '$1 ');

    // Add a space after every 4 characters
    value = value.replace(/(\d{4})/g, '$1 ');

    return value;
  }
}
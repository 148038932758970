import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { Utils } from 'fakturnia-shared';

@Component({
  standalone: true,
  selector: 'date-formatter',
  imports: [CommonModule],
  templateUrl: './date-formatter.component.html',
  styleUrls: ['./date-formatter.component.scss']
})
export class DateFormatterComponent {

  @Input('date') date;
  @Input('showTime') showTime = false;
  
  output;

  ngOnChanges(changes: SimpleChanges): void {
    this.buildFormat()
  }
  
  buildFormat() {
    this.output = Utils.toStringDate(new Date(this.date), this.showTime);
  }
}

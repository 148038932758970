<h1 style="display:none;">Bezpłatny program do wystawiania faktur - Fakturnia.pl to narzędzie, które umożliwia łatwe i szybkie wystawianie faktur online, bez konieczności ponoszenia dodatkowych kosztów. Sprawdź teraz i zacznij zarządzać swoimi fakturami bezpłatnie!</h1>

<section class="bg-white dark:bg-slate-800">
    <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
        <div class="grid grid-cols-2 gap-8">
            <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">
                <h2 class="text-5xl">
                    Pomagamy przedsiębiorcom<br/><strong>w tworzeniu dokumentów</strong>.
                </h2>
                <p class="text-xl mt-3">
                    Nasz program ułatwia proces tworzenia faktur, dając możliwość podglądu dokumentu już w trakcie jego
                    tworzenia.
                </p>
    
                <ul class="list-disc mt-6 text-xl">
                    <li>Pobieraj dane klientów jednym kliknięciem <strong>z Bazy GUS</strong></li>
                    <li> <strong>Buduj bazę klientów</strong> Twojej firmy</li>
                    <li><strong>Dodawaj listę pozycji</strong>, które najczęściej sprzedajesz</li>
                    <li>Twórz szkice faktur<strong>, które wystawisz później</strong></li>
                </ul>
    
                <a [routerLink]="'/rejestracja'">
                    <button class="f-button mb-4 mt-6 text-base lg:text-lg">
                        Dołącz do nas
                        <mat-icon class="text-white ms-2" [svgIcon]="'tabler_outline:arrow-narrow-right'"></mat-icon>
                    </button>
                </a>
            </div>
            <div class="col-span-2 lg:col-span-1">
                <picture>
                    <img src="/assets/images/fakturnia_pdf.png" width="700" height="430" alt="Generuj dokumenty .pdf">
                </picture>
            </div>
        </div>
    </div>
</section>

<section class="bg-white dark:bg-slate-800">
    <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
        <div class="grid grid-cols-2 gap-8">
            <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">
                <picture>
                    <img class="img-fluid" src="/assets/images/fakturnia_protect.png" alt="Bezpieczeństwo danych">
                </picture>
            </div>
            <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">

                <h2 class="text-5xl">
                    Bezpieczeństwo <strong>danych</strong>
                </h2>
                <div class="text-xl mt-3">
                    Przykładamy szczególną wagę do zapewnienia bezpieczeństwa naszej infrastruktury, co jest nieodłącznym
                    elementem naszych usług. Regularnie tworzone są kopie zapasowe danych, co pozwala nam zapewnić ich
                    integralność i dostępność nawet w przypadku nieprzewidzianych zdarzeń.
                    <br />
                    <br />
                    <div class="highlight-blue">
                        Dane są przechowywane na serwerach o najwyższych standardach bezpieczeństwa, gdzie są chronione
                        przez
                        zaawansowane mechanizmy ochronne oraz regularnie monitorowane w celu wykrywania i reagowania na
                        ewentualne zagrożenia.
                    </div>
    
                    <br />
                    <br />
                    Dzięki tym środkom, możemy zagwarantować naszym użytkownikom, że ich dane są
                    bezpieczne i poufne.
                </div>

            </div>
        </div>
    </div>
</section>

<join-us></join-us>

<section class="bg-white dark:bg-slate-800">
    <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
        <div class="grid grid-cols-2 gap-8">
            <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">
                <h2 class="text-5xl header-highlight-blue">
                    Dostęp <strong>bez przerw</strong>
                </h2>
                <div class="text-xl mt-3">
                    Dokładamy wszelkich starań, aby zapewnić naszym użytkownikom nieprzerwany dostęp do naszego systemu
                    generowania faktur oraz ich danych. Naszym priorytetem jest utrzymanie wysokiej dostępności naszej
                    usługi, gwarantując, że nasi klienci mogą korzystać z niej bez zakłóceń w każdym momencie.
                    <br />
                    <br />
                    <div class="highlight-blue">
                        Stosujemy zaawansowane technologie oraz podejmujemy środki zapobiegawcze, aby minimalizować
                        ryzyko wystąpienia ewentualnych przerw w działaniu naszego systemu.
                    </div>
    
                    <br />
                    <br />
                    Nasi użytkownicy mogą
                    być pewni, że zawsze będą mieli szybki i niezawodny dostęp do swoich danych oraz naszego serwisu, co
                    umożliwia im skupienie się na prowadzeniu swoich biznesów bez obaw o utratę danych czy przerwy w
                    obsłudze.
                </div>
            </div>
            <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">
                <picture>
                    <img class="" src="/assets/images/cloud_sync.png" alt="Dane w chmurze">
                </picture>
            </div>
        </div>
    </div>
</section>

<read-more [path]="'/funkcjonalnosci-aplikacji'" [text]="'Funkcjonalności'"></read-more>

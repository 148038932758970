<div class="max-w-400 mx-auto px-4 overflow-y-hidden">

  <!-- Header and buttons -->
  <div class="grid grid-cols-1 md:grid-cols-2 w-full mt-6">

    <div>
      <div class="text-lg pl-4">{{invoice._id != null ? 'Edycja dokumentu' : 'Nowa faktura'}}</div>

      <textarea [rows]="rows" matTooltip="Numer dokumentu" class="mb-0 blank text-3xl font-semibold bg-transparent invoice-number-input hover:bg-white outline-1 outline-gray-300 focus:outline hover:outline dark:outline-gray-500 focus:bg-white dark:hover:bg-slate-800 dark:focus:bg-slate-800"
        type="text" placeholder="Faktura *** **/**/****" [value]="invoice.invoiceName"
        (change)="invoice.set('invoiceName', $event.target.value)"></textarea>
      <div *ngIf="invoice?.errors?.invoiceName?.required" class="pl-3 error-text">Pole wymagane</div>
    </div>

    <div class="ml-auto space-x-2">

      <button class="mt-2" color="primary" mat-flat-button *ngIf="!invoice?._id" (click)="createDocument()">
        <mat-icon [svgIcon]="'tabler_outline:plus'"></mat-icon>
        <span class="ml-2">Utwórz dokument</span> 
      </button>

      <button class="mt-2" color="primary" mat-flat-button *ngIf="invoice?._id" (click)="updateDocument()">
        <mat-icon [svgIcon]="'tabler_outline:check'"></mat-icon>
        <span class="ml-2">Zapisz dokument</span> 
      </button>

      <button [matMenuTriggerFor]="menu" class="mt-2" mat-stroked-button >
        <mat-icon [svgIcon]="'tabler_outline:tool'"></mat-icon>
        <span class="ml-2">Dostosuj</span> 
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="showUpdateSellerDialog()">
          <mat-icon [svgIcon]="'tabler_outline:pencil'"></mat-icon>
          Edytuj dane sprzedawcy
        </button>
        <button mat-menu-item (click)="showToggleFormFieldsDialog()">
          <mat-icon [svgIcon]="'tabler_outline:settings'"></mat-icon>
          Dostosuj dokument
        </button>
      </mat-menu>

      <button class="mt-2" color="primary" mat-stroked-button *ngIf="invoice?._id"  [routerLink]="'/konto/dokumenty/'+invoice?._id+'/podglad'" [matTooltip]="'Powrót do widoku opcji'">
        <mat-icon [svgIcon]="'tabler_outline:arrow-right'"></mat-icon>
        <span class="ml-2"> Wróć do opcji</span> 
      </button>

      <button class="lg:hidden mt-2" color="primary" mat-stroked-button   (click)="togglePreview()" [matTooltip]="'Podgląd'">
        <mat-icon [svgIcon]="'tabler_outline:external-link'"></mat-icon>
        <span class="ml-2"> Podgląd</span> 
      </button>
     
    </div>
  </div>

  <!-- Form and preview -->
  <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-4 mt-2">

    <!-- Form -->
    <div class="bg-card col-span-12 lg:col-span-8 w-full p-4 rounded-xl max-w-256">

      <div class="grid grid-cols-1 md:grid-cols-2">

       

        <!-- Left form side -->
        <div>

          <!-- Buyer and document select -->
          <div class="controls-container bg-neutral-100 dark:bg-slate-900 grid grid-cols-1 gap-y-2">


            <select-client-control label="Nabywca" (onEditClick)="showCreateOrUpdateClientDialog(invoice.buyer, 'buyer')"
              [value]="invoice.buyer.name" (onClientSelect)="updateBuyer($event)">
            </select-client-control>

            <div *ngIf="(invoice.buyer._id == null || invoice.buyer._id == '')" class="mt-1 ms-2 error-text">
              Wybierz nabywcę
            </div>
             
              <ng-container *ngIf="invoice.hasReceiver">
                <select-client-control label="Odbiorca" (onEditClick)="showCreateOrUpdateClientDialog( invoice.receiver, 'receiver')"
                [value]="invoice.receiver.name" (onClientSelect)="updateReceiver($event)">
              </select-client-control>
  
              <div *ngIf="(invoice.receiver._id == null || invoice.receiver._id == '')" class="mt-1 ms-2 error-text">
                Wybierz odbiorcę
              </div>
              </ng-container>
           
              <mat-form-field class="w-full" subscriptSizing="dynamic" [matTooltip]="mode == 'edit' ? 'Nie można zmienić typu edytowanego dokumentu.' : ''">
                <mat-label>Typ dokumentu</mat-label>
                <mat-select [disabled]="mode == 'edit' ? true : false" [value]="invoice.documentType" (valueChange)="switchDocument($event)">
                  <mat-option [value]="documentType.id" *ngFor="let documentType of availableDocs">{{documentType.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              
              <mat-form-field *ngIf="document.config.hasMarginProcedure" class="w-full" subscriptSizing="dynamic">
                <mat-label>Oznaczenie marży</mat-label>
                <mat-select [value]="invoice.marginProcedureId" (valueChange)="setMarginProcedure($event)">
                  <mat-option [value]="procedure.id" *ngFor="let procedure of marginProcedures">{{procedure.text}}</mat-option>
                </mat-select>
              </mat-form-field>

          </div>

          <!-- Faktura korygująca -->
          <div class="mt-2 controls-container bg-neutral-100 dark:bg-slate-900" *ngIf="document.id == 'FakturaKorygujaca'">
            <div class="font-medium text-base">Korygowany dokument</div>

            <button *ngIf="invoice.correctedInvoice == null" mat-button class="text-md p-0" color="primary" (click)="showSelectCorrectedInvoiceDialog()">
              <mat-icon iconPositionEnd [svgIcon]="'tabler_outline:chevron-right'"></mat-icon>
              <span class="ml-2">Wybierz dokument</span>
            </button>

            <div class="  flex items-center" *ngIf="invoice.correctedInvoice != null">
              {{invoice.correctedInvoice.invoiceName}}

              <button color="warn" matTooltip="Kliknij, aby usunąć korygowany dokument" mat-button class="text-md"  (click)="invoice.removeCorrectedInvoice()">
                Usuń
              </button>

            </div>
          </div>
      
          <!-- Faktura końcowa -->
          <div class="mt-2 controls-container bg-neutral-100 dark:bg-slate-900" *ngIf="document.id == 'FakturaKoncowa'">
            
            <div class="font-medium text-base">
              Powiązane faktury zaliczkowe
              <small class="text-secondary" *ngIf="invoice.prepayments.length > 0">({{invoice.prepayments.length}})</small>
            </div>

            <button mat-button class="text-md p-0" color="primary" (click)="showSelectPrepaymentDialog()">
              <mat-icon iconPositionEnd [svgIcon]="'tabler_outline:plus'"></mat-icon>
              <span class="ml-2">Dodaj dokument </span>
            </button>
            
            <div *ngIf="invoice.prepayments.length > 0">
              <div class="flex items-center" *ngFor="let prepayment of invoice.prepayments; let i = index">
                {{prepayment.invoiceName}}
                <button color="warn" matTooltip="Kliknij, aby usunąc fakturę z tego dokumentu" mat-button class="text-md"  (click)="invoice.removePrepayment(i)">
                  Usuń
                </button>
              </div>
            </div>

          </div>

          <!-- Payments -->
          <div>
            <!-- Płatność -->
            <div class="controls-container bg-neutral-100 dark:bg-slate-900 grid grid-cols-3 gap-2 mt-3">

              <div class="col-span-3 md:col-span-1">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Status płatności</mat-label>
                  <mat-select [value]="invoice.paymentStatus.value" (valueChange)="invoice.setPaymentStatus($event)">
                    <mat-option [value]="1">Do zapłaty</mat-option>
                    <mat-option [value]="0">Zapłacono</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-span-3 md:col-span-2">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Sposób płatności</mat-label>
                  <mat-select [value]="invoice.paymentMethod.value" (valueChange)="invoice.setPaymentMethod($event)">
                    <mat-option *ngFor="let payment of paymentMethods" [value]="payment.index">{{payment.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-span-3">
                <a class="cursor-pointer mt-2 fs-14" *ngIf="!invoice.bankAccountNumber.show"
                  (click)="invoice.setShowBankAccountNumber(true)">
                  Dodaj numer konta
                </a>
               
                <mat-form-field class="w-full" *ngIf="invoice.bankAccountNumber.show && bankAccounts.length > 0" subscriptSizing="dynamic">
                  <mat-label class="flex justify-between">Numer konta bankowego <small class="text-gray-600 dark:text-gray-300" matTooltip="Kliknij, aby nie wyświetlać numeru konta bankowego na fakturze." (click)="invoice.setShowBankAccountNumber(false)">ukryj</small></mat-label>
                  <mat-select [value]="invoice.bankAccountNumber.value" (valueChange)="invoice.setBankAccountNumber($event)">
                    <mat-option [value]="bankAccount.accountNumber" *ngFor="let bankAccount of bankAccounts">
                      {{bankAccount.bank}}:
                      {{bankAccount.accountNumber}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
  
                <mat-form-field class="w-full" *ngIf="invoice.bankAccountNumber.show && bankAccounts.length == 0" subscriptSizing="dynamic">
                  <mat-label class="flex justify-between">Numer konta bankowego <small class="text-gray-600 dark:text-gray-300" matTooltip="Kliknij, aby nie wyświetlać numeru konta bankowego na fakturze." (click)="invoice.setShowBankAccountNumber(false)">ukryj</small></mat-label>
                  <input matInput bankAccountFormat [value]="invoice.bankAccountNumber.value" (input)="invoice.setBankAccountNumber($event.target.value);"/>
                </mat-form-field>

              </div>
             
              <!-- Vat account number -->
              <div class="col-span-3" *ngIf="document.config.hasVATAccount">

                  <mat-form-field class="w-full" *ngIf="bankAccounts.length > 0" subscriptSizing="dynamic">
                    <mat-label>Konto VAT</mat-label>
                    <mat-select [value]="invoice.vatAccountNumber" (valueChange)="invoice.setVatAccountNumber($event)">
                      <mat-option [value]="bankAccount.accountNumber" *ngFor="let bankAccount of bankAccounts">
                        {{bankAccount.bank}}:
                        {{bankAccount.accountNumber}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="w-full" *ngIf="bankAccounts.length == 0" subscriptSizing="dynamic">
                    <mat-label>Konto VAT</mat-label>
                    <input matInput bankAccountFormat [value]="invoice.vatAccountNumber" (input)="invoice.setVatAccountNumber($event.target.value);"/>
                  </mat-form-field>

              </div>

              <!-- Payment description -->
              <div class="col-span-3" *ngIf="invoice.hasPaymentDescription">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Opis płatności</mat-label>
                  <textarea matInput cols="30" rows="2" [value]="invoice.paymentDescription" placeholder="Dodaj opis płatności"
                  (change)="invoice.set('paymentDescription', $event.target.value);"></textarea>
                </mat-form-field>
              </div>
              
              <div class="col-span-3" *ngIf="invoice.hasComments">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Uwagi</mat-label>
                  <textarea matInput cols="30" rows="2" [value]="invoice.comments" placeholder="Dodaj uwagi"
                  (change)="invoice.set('comments', $event.target.value);"></textarea>
                </mat-form-field>
              </div>
             

   
           

           

              <!-- <div class="mt-1" *ngIf="document.config.hasVATAccount">

                <b>Konto VAT: </b>
                <br />

                <div class="flex">
                  <select *ngIf="bankAccounts.length > 0" #select
                    class="full-clear-select border-bottom-on-hover cursor-pointer" [value]="invoice.vatAccountNumber"
                    (change)="invoice.setVatAccountNumber($event.target.value)">
                    <option value="">Wybierz konto VAT</option>
                    <option [selected]="bankAccount.accountNumber == invoice.vatAccountNumber"
                      [value]="bankAccount.accountNumber" *ngFor="let bankAccount of bankAccounts">
                      {{bankAccount.bank}}:
                      {{bankAccount.accountNumber}}</option>
                  </select>
                  <input *ngIf="bankAccounts.length == 0" bankAccountFormat class="blank w-auto bank-account-input"
                    type="text" placeholder="Podaj numer konta" [value]="invoice.vatAccountNumber"
                    (input)="invoice.setVatAccountNumber($event.target.value);">

                </div>
              </div> -->

              <!-- <a class="cursor-pointer mt-2 fs-14" *ngIf="!invoice.bankAccountNumber.show"
                (click)="invoice.setShowBankAccountNumber(true)">
                Dodaj numer konta
              </a> -->

            </div>
          </div>

        </div>

        <!-- Right form side -->
        <div class="mt-3 md:mt-0 md:w-80 md:max-w-80 md:ml-auto">
          <div class="controls-container bg-neutral-100 dark:bg-slate-900 ">

            <div class="grid grid-cols-1 gap-2">

              <!-- Place of issue -->
              <div *ngIf="generalFieldsToShow.has('issuePlace')">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Miejsce wystawienia</mat-label>
                  <input matInput [value]="invoice.placeHeader.value" (change)="invoice.setPlaceHeaderValue($event.target.value); ">
                </mat-form-field>
              </div>
             
              <!-- Date of issue -->
              <div>
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label >Data wystawienia</mat-label>
                  <input matInput [value]="invoice.issueDateHeader.value" readonly (click)="picker1.open()" (dateChange)="invoice.setIssueDateHeaderValue($event.target.value); checkInvoiceName(); checkExchangeRate()" [matDatepicker]="picker1">
                  <mat-datepicker-toggle matIconSuffix [for]="picker1">
                    <mat-icon matDatepickerToggleIcon [svgIcon]="'tabler_outline:calendar-event'"></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>

              <!-- Sub header date (sale date) -->
              <div>
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label (click)="$event.preventDefault(); $event.stopPropagation()" class="flex items-center cursor-pointer pb-2" [matMenuTriggerFor]="subHeaderMenu">
                    {{invoice.subHeader.text}}
                    <mat-icon  [svgIcon]="'mat_solid:arrow_drop_down'"></mat-icon>
                  </mat-label>
                  <input matInput [value]="invoice.subHeader.value" (click)="picker2.open()" (dateChange)="invoice.setSubHeaderValue($event.target.value)" [matDatepicker]="picker2">
                  <mat-datepicker-toggle matIconSuffix [for]="picker2">
                    <mat-icon matDatepickerToggleIcon [svgIcon]="'tabler_outline:calendar-event'"></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>

                <mat-menu #subHeaderMenu="matMenu" class="max-h-100 pb-0">
                  <div class="sticky top-0 z-10 -mt-3 bg-neutral-100 left-0 w-full px-4 pt-2 pb-2 dark:bg-slate-900 text-sm border-b">
                    Wybierz wyświetlaną nazwę
                  </div>
                  <button *ngFor="let header of subheaderDateHeaders" mat-menu-item (click)="invoice.setSubHeader(header)" >
                    <mat-icon *ngIf="invoice.subHeader.text == header" [svgIcon]="'tabler_outline:check'"></mat-icon>
                    {{header}}
                  </button>
                </mat-menu>
              </div>

              <!-- Additional payment date -->
              <!-- Fields is toggleable by document settings -->
              <div *ngIf="invoice.additionalPaymentDateHeader.show">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label (click)="$event.preventDefault(); $event.stopPropagation()" class="flex items-center cursor-pointer pb-2" [matMenuTriggerFor]="additionalPaymentDateHeaderMenu">
                    {{invoice.additionalPaymentDateHeader.text}}
                    <mat-icon  [svgIcon]="'mat_solid:arrow_drop_down'"></mat-icon>
                  </mat-label>
                  <input matInput [value]="invoice.additionalPaymentDateHeader.value" (click)="picker3.open()" (dateChange)="invoice.setAdditionalPaymentDateValue($event.target.value);" [matDatepicker]="picker3">
                  <mat-datepicker-toggle matIconSuffix [for]="picker3">
                    <mat-icon matDatepickerToggleIcon [svgIcon]="'tabler_outline:calendar-event'"></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>

                <mat-menu #additionalPaymentDateHeaderMenu="matMenu">
                  <div class="px-4 pb-2 text-center text-sm border-b">Wybierz wyświetlaną nazwę</div>
                  <button mat-menu-item (click)="invoice.setAdditionalPaymentDateHeader(header)" *ngFor="let header of paymentDateHeaders">{{header}}</button>
                </mat-menu>
              </div>

              <!-- Payment deadline -->
              <div>
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Termin zapłaty</mat-label>
                  <input matInput [value]="invoice.paymentDeadline.value" (click)="picker4.open()" (dateChange)="invoice.setPaymentDeadlineValue($event.target.value);" [matDatepicker]="picker4">
                  <mat-datepicker-toggle matIconSuffix [for]="picker4">
                    <mat-icon matDatepickerToggleIcon [svgIcon]="'tabler_outline:calendar-event'"></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker4></mat-datepicker>
                </mat-form-field>
              </div>

              <!-- Currency exchange -->
              <div *ngIf="invoice.currency != 'PLN'">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                  <mat-label>Kurs {{invoice.currency}}/PLN</mat-label>
                  <input [disabled]="latestExchangeData != null" matInput exchangeRate [value]="invoice.exchangeRate" (key.up)="resetLatestExchangeDate()" (change)="invoice.setExchangeRate($event.target.value);">
                  <mat-hint class="ml-1" *ngIf="latestExchangeData != null">
                    Kurs NBP z dnia: <span class="ml-1">{{latestExchangeData.date | date: 'dd/MM/YYYY'}}.</span>
                  </mat-hint>

                  <button *ngIf="latestExchangeData != null" mat-icon-button matSuffix (click)="resetExchangeRate()">
                    <mat-icon [svgIcon]="'tabler_outline:x'"></mat-icon>
                  </button>

                  <button *ngIf="latestExchangeData == null" mat-icon-button matSuffix (click)="checkExchangeRate()">
                    <mat-icon [svgIcon]="'tabler_outline:zoom-question'"></mat-icon>
                  </button>
                </mat-form-field>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="mt-6">
        <div class="text-xl font-semibold px-2" *ngIf="document.id != 'FakturaKorygujaca'">
          Pozycje
        </div>
        <div class="text-xl font-semibold px-2" *ngIf="document.id == 'FakturaKorygujaca'">
          Po korekcie
        </div>

        <div class="overflow-x-auto">

          <div class="product-row px-2">
            <div class="field" *ngFor="let header of document.table.viewHeaders" [class]="header.class">
              {{header.text}}
            </div>
          </div>

          <div class="products-bg bg-neutral-100 dark:bg-slate-900 p-2 rounded-xl">
            <ng-container *ngFor="let p of invoice.products; let i = index;">
              <div class="product-row flex items-center content-center ">

                <ng-container *ngFor="let tableHeader of document.table.viewHeaders">
    
                  <ng-container *ngIf="tableHeader.name == 'name'">
                    <div class="field xl relative" >
                      <input [matAutocomplete]="auto" #productName type="text" [value]="p.name" (change)="p.setName($event.target.value)" (input)="findProducts($event.target.value);" />
    
                      <span (click)="productName.click()" *ngIf="productName.value == ''" class="absolute bottom-1 ms-2 error-text pointer-events-none">Wprowadź nazwę</span>
    
                      <mat-autocomplete (optionSelected)="onOptionSelected($event, p )" #auto="matAutocomplete" class="relative pt-0 rounded-xl shadow-xl">
                        <div class="sticky top-0 z-10 -mt-6 mb-6 bg-neutral-200 left-0 px-4 pt-2 pb-2 dark:bg-slate-900 text-sm border-b">
                          Szukaj produkt
                        </div>
                        <mat-option *ngFor="let product of foundProducts" [value]="product" (click)="p.update(product)" class="border-b">
                          <div class="text-md font-medium">{{ product.name }}</div> 
                          <div class="text-sm">{{product.grossValue}} {{product.currency || 'PLN'}}, {{product.quantity}}x, Stawka: {{product.vatRate}}{{product.taxSymbol}}</div>
                        </mat-option>
                      </mat-autocomplete>

                    </div>
                  </ng-container>
    
                  <ng-container *ngIf="tableHeader.name == 'unitOfMeasure'">
                    <div class="field xs">
  
                      <div class="realtive flex content-between cursor-pointer align-center w-13 items-center bg-card border dark:border-white p-1 pl-2 rounded-md" style="max-height:31px" [matMenuTriggerFor]="unitOfMeasureMenu">
                        <span class="text-clip truncate w-10 text-sm">{{p.unitOfMeasure}}</span> 
                        <mat-icon class="w-3" [svgIcon]="'mat_solid:arrow_drop_down'"></mat-icon>
                      </div>
                      
                      <mat-menu #unitOfMeasureMenu="matMenu" class="relative max-h-100">
                        <div class="sticky top-0 z-10 -mt-3 bg-neutral-200 left-0 w-i px-4 pt-2 pb-2 dark:bg-slate-900 text-sm border-b">
                          Jednostka miary
                        </div>
                        <button *ngFor="let measurementUnit of measurementUnits" mat-menu-item (click)="p.setUnitOfMeasure(measurementUnit.value)">
                          <mat-icon  *ngIf="p.unitOfMeasure == measurementUnit.value" [svgIcon]="'tabler_outline:check'"></mat-icon>
                          {{measurementUnit?.viewValue}}
                        </button>
                      </mat-menu>
                    </div>
                  </ng-container>
    
                  <ng-container *ngIf="tableHeader.name == 'quantity'">
                    <div class="field xs">
                      <input inputmode="numeric" quantity [value]="p.quantity" (change)="p.setQuantity($event.target.value)" />
                    </div>
                  </ng-container>
    
                  <ng-container *ngIf="tableHeader.name == 'netPrice'">
                    <div class="field md">
                      <input decimalFormatter inputmode="numeric" step=".01" [value]="p.netPrice?.toFixed(2)"
                        (change)="p.setNetPrice($event.target.value);" />
                    </div>
                  </ng-container>
    
                  <ng-container *ngIf="tableHeader.name == 'netValue'">
                    <div class="field md text-center">
                      {{toDecimal(p.netValue)}}
                    </div>
                  </ng-container>
    
                  <ng-container *ngIf="tableHeader.name == 'vatRate'">
                    <div class="field xs">
  
                      <div class="flex content-between cursor-pointer align-center items-center  bg-card border dark:border-white p-1 pl-2 rounded-md" style="max-height:31px" [matMenuTriggerFor]="vatRatesMenu">
                        {{p.vatRate}} <mat-icon [svgIcon]="'mat_solid:arrow_drop_down'"></mat-icon>
                      </div>
  
                      <mat-menu #vatRatesMenu="matMenu" class="relative max-h-100">
                        <div class="sticky top-0 z-10 -mt-3 bg-neutral-100 left-0 w-full px-4 pt-2 pb-2 dark:bg-slate-900 text-sm border-b">
                          Stawka VAT
                        </div>
  
                        <button *ngFor="let rate of vatRates" mat-menu-item (click)="p.setTax(rate.value)">
                          <mat-icon  *ngIf="p.vatRate == rate.value" [svgIcon]="'tabler_outline:check'"></mat-icon>
                          {{rate?.viewValue}}
                        </button>
  
                        <div class="p-4">
                          Aby wyświetlać inne stawki <br/>
                          przejdz do <a routerLink="/konto/ustawienia/stawki-vat">ustawień stawek</a>.
                        </div>
                      </mat-menu>
                    </div>
                  </ng-container>
    
    
                  <ng-container *ngIf="tableHeader.name == 'amountOfVat'">
                    <div class="field md text-center">
                      {{toDecimal(p.amountOfVat)}}
                    </div>
                  </ng-container>
    
                  <ng-container *ngIf="tableHeader.name == 'grossValue'">
                    <div class="field md">
                      <input decimalFormatter inputmode="numeric" step=".01" [value]="p?.grossValue?.toFixed(2)"
                        (change)="p.setGrossValue($event.target.value)" />
                    </div>
                  </ng-container>
    
    
                  <ng-container *ngIf="tableHeader.name == 'options'">
                    <div class="field xs items-center text-center justify-self-center self-center place-self-center">
  
                      <button mat-icon-button (click)="removeProduct(i)"> 
                        <mat-icon [svgIcon]="'tabler_outline:x'" ></mat-icon>
                      </button>
                     
                    </div>
                    <!-- [matMenuTriggerFor]="menu"
                    <mat-menu #menu="matMenu" yPosition="above">
                      <button class="px-1 py-0 m-0" mat-menu-item (click)="removeProduct(i)" >
                        <mat-icon color="danger">delete</mat-icon>
                        Usuń
                      </button>
                    </mat-menu> -->
                  </ng-container>
    
    
                </ng-container>
    
              </div>
              <div class="block product-row p-2" *ngIf="p?._id == null && p?.grossValue > 0">
                Czy chcesz zapisać ten produkt? <span class="text-primary cursor-pointer" (click)="saveProduct(p)">Zapisz</span>
              </div>
              <div *ngIf="invoice.hasGTU || invoice.hasDiscount" class="flex product-row py-2 border-b">
  

                <!-- <div class="field md" *ngIf="invoice.hasDiscount">
                  Rabat %<br/>
                  <input decimalFormatter inputmode="numeric" step=".01" [value]="p?.discount?.toFixed(2)"
                  (change)="p.setDiscount($event.target.value)" />
                </div> -->
              <!-- <select-dropdown *ngIf="invoice.hasGTU" [label]="'GTU'" class="me-2" [options]="gtu" [selectedOption]="p.gtu" (selectionChange)="p.setGTU($event)"></select-dropdown> -->
              <select-dropdown *ngIf="invoice.hasDiscount" [label]="'Rabat %'" [toggleClass]="'no-arrow'" type="input"
                 [selectedOption]="p.discount"
                (selectionChange)="p.setDiscount($event)"></select-dropdown>
  
            </div>
            </ng-container>
            
          </div>
          

        </div>

        <button mat-button class="text-md mt-2" color="primary" (click)="addProduct()">
          <mat-icon [svgIcon]="'tabler_outline:plus'"></mat-icon>
          <span class="ml-2">Dodaj kolejny produkt</span>
        </button>

        <div class="text-lg text-primary w-auto cursor-pointer ml-2 mt-3" (click)="addProduct()">
        
        </div>
      </div>


      <div class="mt-2 summary-row">
          <div class="sum-label text-end sum-price-title">
            {{invoice.sum.label}}
            <span class="sum-price">
              {{invoice.sum.value}} <span class="currency">{{invoice.currency}}</span>
              <br />
              <span class="sum-payment-status-text">{{invoice.paymentStatus.text}}</span>
            </span>
          </div>
      </div>
    </div>

    <!-- Preview -->
    <div class="col-span-12 lg:col-span-4 w-full">

      <div class="relative h-200 lg:h-full" [ngClass]="{'full-screen-preview' : showingPreview}">
        
        <div class="preview-backdrop rounded-xl" (click)="togglePreview()"></div>

        <ngx-extended-pdf-viewer *ngIf="dataUrl" [base64Src]="dataUrl" backgroundColor="#d8d8d8" [height]="'100%'"
          [showToolbar]="false" [showSidebarButton]="false" [showFindButton]="false" [showPagingButtons]="false"
          [showDrawEditor]="false" [showStampEditor]="false" [showTextEditor]="false" [showZoomButtons]="true"
          [showPresentationModeButton]="false" [showOpenFileButton]="false" [showPrintButton]="false"
          [showDownloadButton]="false" [showSecondaryToolbarButton]="false" [showRotateButton]="undefined"
          [showHandToolButton]="true" [showScrollingButton]="false" [showSpreadButton]="false"
          [showPropertiesButton]="false" [zoom]="zoom">
        </ngx-extended-pdf-viewer>
        <div style="position:absolute; top:0;right: 0; padding: 8px;">
         
          <button class="bg-gray-50 dark:bg-slate-900 opacity-70" mat-icon-button (click)="zoomIn()" [matTooltip]="'Powiększ'">
            <mat-icon *ngIf="!showingPreview" svgIcon="tabler_outline:zoom-in"></mat-icon>
          </button>
          <button class="bg-gray-50 dark:bg-slate-900 opacity-70" mat-icon-button (click)="zoomOut()" [matTooltip]="'Pomniejsz'">
            <mat-icon *ngIf="!showingPreview" svgIcon="tabler_outline:zoom-out"></mat-icon>
          </button>

          <button class="bg-gray-50 dark:bg-slate-900 opacity-70" mat-icon-button (click)="togglePreview()" [matTooltip]="showingPreview ? 'Zamknij podgląd' : 'Pełny podgląd'">
            <mat-icon *ngIf="!showingPreview" svgIcon="tabler_outline:maximize"></mat-icon>
            <mat-icon *ngIf="showingPreview" svgIcon="tabler_outline:minimize"></mat-icon>
          </button>

        </div>

        <div class="absolute grid place-content-center bottom-4 w-full" *ngIf="showingPreview">
          <button (click)="closePreview()" class="mx-auto w-60" mat-raised-button color="primary">Zamknij podgląd</button>
        </div>
      </div>
    </div>
  </div>
</div>


import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductInformationsComponent } from '../product-preview/product-informations/product-informations.component';

@Component({
  selector: 'app-create-product',
  standalone: true,
  imports: [CommonModule, ProductInformationsComponent],
  templateUrl: './create-product.component.html',
  styleUrl: './create-product.component.scss'
})
export class CreateProductComponent {

}

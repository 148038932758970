import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, BehaviorSubject, take } from 'rxjs';
import { DatabaseService } from 'shared/services/database.service';
import { Utils } from 'fakturnia-shared';
import { debounce } from 'lodash'
import { PanelService } from 'shared/services/panel.service';
import { ApiService } from 'shared/services/api.service';
import { DialogService } from 'shared/services/dialog.service';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { DataSynchronizerService } from 'shared/services/data-synchronization.service';
import { ClientsService } from 'shared/services/clients.service';
import { APIResponse } from 'fakturnia-shared';
import { NotificationData } from 'fakturnia-shared';
import { ToastService } from 'shared/services/toast.service';
import { DbClient } from 'fakturnia-shared';
import { ClientsApiService } from 'shared/services/api/clients.api.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { PaginationComponent } from 'shared/components/pagination/pagination.component';
import { DateFormatterComponent } from 'shared/components/date-formatter/date-formatter.component';
import { RouterLink } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ClientsTableComponent } from 'shared/components/clients-table/clients-table.component';

@Component({
  standalone: true,
  selector: 'app-clients',
  imports: [CommonModule, ClientsTableComponent, MatButtonModule, MatCheckboxModule, RouterLink, MatIconModule, ReactiveFormsModule, PaginationComponent, DateFormatterComponent],
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnDestroy {

  private _subscriptions: Subscription[] = [];


  searchResults: any;
  clients: DbClient[] = [];


  // Pagination
  allPages = 0;
  currentPage = 1;

  count = 0;

  selected = new Set<string>();

  filters = [
    (client) => client.isDeleted == false
  ]
  
  constructor(
    private _synchronizationService: SynchronizationService,
    private _clientsApiService: ClientsApiService,
    private _dialogService: DialogService,
    private _clientsService: ClientsService,
    private _toastService: ToastService
  ) {

   

  }
  
  public showAddClientDialog() {
    this._subscriptions.push(
      this._dialogService.showAddClientDialog()
        .subscribe({
          next: (response: any) => {

            if (!response) return
            if (typeof response.success == 'undefined') return
            if (!response.success) return

            // this.selectClient(response.data)
            // GOTO CLIENT
          }
    }))
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  deleteSelected() {

    if (typeof this.selected === 'undefined') return;
    if (this.selected.size === 0) return;

    this._subscriptions.push(this._dialogService.confirmDialog({
      title: "Usuwanie zaznaczonych",
      message: `Chcesz usunąć zaznaczone elementy? Wybranych klientów: ${this.selected.size}`,
      type: "danger",
      confirmText: "Usuń"
    }).subscribe({
      next: (data) => {

        if (data == true) {
          this._subscriptions.push(
            this._clientsApiService.deleteMany(Array.from(this.selected))
              .pipe(take(1))
              .subscribe({
                next: (response: APIResponse) => {

                  if (response.success != true) {
                    this._toastService.showToast(new NotificationData({
                      title: 'Wystąpił błąd',
                      status: 'warning',
                      message: response.message,
                    }))
                    return
                  }

                  this._synchronizationService.synchronize()
                  this._toastService.showToast(new NotificationData({
                    title: 'Informacja',
                    status: 'info',
                    message: 'Operacja usuwania się powiodła.',
                  }))

                  this.selected = new Set()
                }
              }))
        }
      }
    }
    ))

  }
}

import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Utils } from 'fakturnia-shared';
import { SnackbarService } from 'shared/services/snackbar.service';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatDialogModule],
  selector: 'app-display-form-errors-dialog',
  templateUrl: './display-form-errors-dialog.component.html',
  styleUrls: ['./display-form-errors-dialog.component.scss']
})
export class DisplayFormErrorsDialogComponent {

  public errors: { field: string, errors: string[] }[] = []

  constructor(
    private _dialog: MatDialogRef<DisplayFormErrorsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackbarService:SnackbarService
  ) {

    if(Utils.isDefined(data,"errors")) {
      this.errors = data.errors
      return
    }

    this.close()
  }

  close() {
    this._dialog.close()
  }
  
}

import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { APIResponse } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { Utils } from 'fakturnia-shared';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { UpdateCompanyDataComponent } from './update-user-company-data/update-user-company-data.component';
import { UpdateUserPersonalInformationsComponent } from './update-user-personal-informations/update-user-personal-informations.component';

@Component({
  standalone: true,
  imports: [CommonModule, MatTabsModule, MatButtonModule, UpdateCompanyDataComponent, UpdateUserPersonalInformationsComponent],
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {

  private _subscriptions:Subscription[] = []
  user:any = null
  isEditable = false
  constructor(
    private _route:ActivatedRoute,
    private _apiService:ApiService
  ) {
    this._subscriptions.push(this._route.params.subscribe(
      (data:any)=> {
        if(data != null)
        {
          if(!Utils.isNullOrEmpty(data.id))
          {
            this._getUser(data.id);
          }
        }
      }
    ))
  }

  private _getUser(userId) {
    this._apiService.getUser(userId)
    .pipe(take(1))
    .subscribe({
      next: (response:APIResponse) => {
        this.user = response.data
      }
    })
  }
 
}

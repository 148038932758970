import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, forkJoin, from, map, of, tap } from "rxjs";
import { DataSynchronizerService } from "./data-synchronization.service";
import { DbService } from "./db.service";
import { Utils } from 'fakturnia-shared';
import { AppDb } from "fakturnia-shared";
import { DbClient } from "fakturnia-shared";

@Injectable({
    providedIn: 'root'
})
export class ClientsService implements OnDestroy {

    private _subscriptions: Subscription[] = [];
    private _clients = new BehaviorSubject<DbClientObject>({});
    private _db: AppDb

    currentDocument = this._clients.asObservable();

    constructor(
        private _dbService: DbService,
        private _dataSynchronizerService: DataSynchronizerService
    ) {
        this._subscriptions.push(this._dbService.getDatabase().subscribe({
            next: (data) => {
                this._db = data
            }
        }))

        this._subscriptions.push(this._dataSynchronizerService.currentData.subscribe({
            next: (data) => {
                if (!data) return
                if (data.table != 'clients') return
                if (data.value == null) return
                if (typeof data.value == 'undefined') return

                // Update
                data.value.forEach((client: DbClient) => {
                    this._clients.value[client._id] = client
                });

                // Push changes
                this._clients.next(this._clients.value)

                this._dataSynchronizerService.received()
            }
        }))
    }

    public getById(id) {
        const client = this._clients.value[id]
        if (!client) {
            console.log(`[ClientsService]: Client with id: ${id} not found.`)
            return null
        }
        return client
    }

    filterClients(value: string): DbClient[] {
        if (Utils.isNullOrEmpty(value)) return []
        value = value.toLowerCase();
        return Object.entries(this._clients.value)
            .map(x => x[1]).filter((x: any) => {
                return (x.name.toLowerCase().includes(value) && !x.isDeleted)
                    || (x.nip.toLowerCase().includes(value) && !x.isDeleted)
            });
    }

    browseClients({ currentPage, pageSize, activeFilters, filters }): PaginatedDbClients {

        let t1 = new Date().getTime();

        let fromIndex = (currentPage * pageSize) - pageSize;
        let toIndex = fromIndex + (pageSize - 1);

        let results: any = Object.entries(this._clients.value)
            .map(x => x[1])

        if (filters.length > 0) {
            filters.forEach(filter => {
                results = results.filter(filter)
            })
        }

        results.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        activeFilters.forEach((element: any) => {

            let key = element[0];
            let value = element[1];

            let filterValue = value;
            if (typeof filterValue == 'string') { filterValue = filterValue.toLowerCase() }

            if (key == 'searchText') {
                results = results.filter(
                    (client: DbClient) => {
                        let found = false;
                        let name = '';
                        let nip = client.nip;
                        let regon = client.nip;
                        let krs = client.nip;
                        let pesel = client.nip;
                        let firstName = '';
                        let lastName = '';
                        let nazwiskoImie = '';
                        let imieNazwisko = '';

                        if (Utils.isNullOrEmpty(client)) return false;
                        if (!Utils.isNullOrEmpty(client['name'])) { name = client['name'].toLowerCase(); }
                        if (!Utils.isNullOrEmpty(client['firstName'])) { firstName = client['firstName'].toLowerCase(); }
                        if (!Utils.isNullOrEmpty(client['lastName'])) { lastName = client['lastName'].toLowerCase(); }

                        if (
                            firstName.length > 0 && lastName.length > 0
                        ) {
                            imieNazwisko = `${firstName} ${lastName}`
                            nazwiskoImie = `${lastName} ${firstName}`
                        }

                        if (name.includes(filterValue)) { found = true }
                        if (firstName.includes(filterValue)) { found = true }
                        if (imieNazwisko.includes(filterValue)) { found = true }
                        if (nazwiskoImie.includes(filterValue)) { found = true }
                        if (nip.includes(filterValue)) { found = true }
                        if (regon.includes(filterValue)) { found = true }
                        if (krs.includes(filterValue)) { found = true }
                        if (pesel.includes(filterValue)) { found = true }

                        return found;
                    })
            }
        })

        if (toIndex > results.length - 1) {
            toIndex = results.length - 1;
        }

        const paginationResult: PaginatedDbClients = {
            executionTime: new Date().getTime() - t1,
            currentPage: currentPage,
            pages: Math.ceil(results.length / pageSize),
            pageSize: pageSize,
            resultsCount: results.length,
            results: Utils.getObjectsInRange(results, fromIndex, toIndex),
        }

        return paginationResult

    }

    getAll(): Observable<any> {
        if (!this._db) return of(null)
        const s1 = new Date().getTime();
        const clientsLoader = this._db.transaction("r", this._db.clients, () => {
            return this._db.clients.orderBy('createdAt').reverse().toArray();
        })
            .then((result: DbClient[]) => {
                const object: DbClientObject = {};
                result.forEach(client => {
                    object[client._id] = client;
                });
                return object;
            });

        return forkJoin([from(clientsLoader)]).pipe(
            map(([clients]) => {
                // Update your subjects
                this._clients.next(clients);
                return { clients };
            }),
            tap(() => console.log(`[Clients Service]: Built in ${new Date().getTime() - s1}ms.`))
        );
    }


    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }

}

export interface DbClientObject {
    [key: number]: DbClient;
}
export interface PaginatedDbClients {
    results: DbClient[] | null
    resultsCount: number
    executionTime: number
    pages: number
    pageSize: number
    currentPage: number
}
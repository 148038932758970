import { Component, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { PanelService } from 'shared/services/panel.service';
import { ApiService } from 'shared/services/api.service';
import { DialogService } from 'shared/services/dialog.service';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { NotificationData } from 'fakturnia-shared';
import { ToastService } from 'shared/services/toast.service';
import { APIResponse } from 'fakturnia-shared';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ProductsTableComponent } from 'shared/components/products-table/products-table.component';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-products',
  imports: [CommonModule, RouterLink, MatIconModule, MatButtonModule, ProductsTableComponent],
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnDestroy{

  private _subscriptions: Subscription[] = [];
  
  filters = [
    (product) => product.isDeleted == false
  ]

  constructor () {}

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }
}


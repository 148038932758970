import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ModernLayoutComponent } from './layouts/modern-layout/modern-layout.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MediaWatcherService } from 'shared/services/media-watcher.service';
import { ConfigService } from '@fakturnia/services/config/config.service';
import { FakturniaConfig } from '@fakturnia/services/config/config.types';
import { Subject, combineLatest, filter, map, takeUntil } from 'rxjs';
import { ObjectUtils, UserSettings, Utils } from 'fakturnia-shared';
import { SettingsService } from 'shared/services/settings.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [CommonModule, ModernLayoutComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit, OnDestroy {

  layout: string = 'modern'
  config: FakturniaConfig;
  scheme: 'dark' | 'light';
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  
  /**
  * Constructor
  */
  constructor(
    private _activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private _document: any,
    private _renderer2: Renderer2,
    private _router: Router,
    private _configService: ConfigService,
    private _mediaWatcherService: MediaWatcherService,
    private _settingsService: SettingsService
    // private _platformService: PlatformService
  ) {

    this._settingsService.getUserSettings()
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe({
        next: (userSettings:UserSettings) => {
            if(ObjectUtils.isDefined(userSettings,"settings.general.scheme")) {
              const scheme = ObjectUtils.getNestedValue(userSettings,"settings.general.scheme")
              this._configService.config = { scheme: scheme }
            }
        }
    })

  }

  /**
   * Update the selected scheme
   *
   * @private
   */
  private _updateScheme(): void {
    // Remove class names for all schemes
    this._document.body.classList.remove('light', 'dark');

    // Add class name for the currently selected scheme
    this._document.body.classList.add(this.scheme);
  }

  /**
   * On init
   */
  ngOnInit(): void {

    // Set the theme and scheme based on the configuration
    combineLatest([
      this._configService.config$,
      this._mediaWatcherService.onMediaQueryChange$(['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)']),
    ]).pipe(
      takeUntil(this._unsubscribeAll),
      map(([config, mql]) => {
        const options = {
          scheme: config.scheme,
          theme: config.theme,
        };

        // If the scheme is set to 'auto'...
        if (config.scheme === 'auto') {
          // Decide the scheme using the media query
          options.scheme = mql.breakpoints['(prefers-color-scheme: dark)'] ? 'dark' : 'light';
        }

        return options;
      }),
    ).subscribe((options) => {
      // Store the options
      this.scheme = options.scheme;
      // this.theme = options.theme;

      // Update the scheme and theme
      this._updateScheme();
    });
   
  }

  /**
  * On destroy
  */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}

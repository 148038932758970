import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription, take, firstValueFrom } from "rxjs";
import { DbService } from "./db.service";

import { HttpClient } from "@angular/common/http";
import { DataSynchronizerService } from "./data-synchronization.service";
import { ToastService } from "./toast.service";
import Dexie from "dexie";
import { MatDialog } from "@angular/material/dialog";
import { LoadingDialogComponent } from "../dialogs/loading-dialog/loading-dialog.component";
import { AppDb } from 'fakturnia-shared'
import { DbSynchronization } from "fakturnia-shared";
import { APIResponse } from "fakturnia-shared";
import { API_HOST } from "environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SynchronizationService implements OnDestroy {

    private _subscriptions: Subscription[] = [];


    private _synchronizationStatus = new BehaviorSubject<any>({ isSynchronizing: false, hasSuccessfulSynchronize: false, changeOccured: false, currentPage: 0, allPages: 0 });
    currentSynchronizationStatus = this._synchronizationStatus.asObservable();
    private _tables = [
        'documents',
        'clients',
        'products',
        'activityLogs'
    ]

    private _db: AppDb

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }

    constructor(
        private _dbService: DbService,
        private _httpClient: HttpClient,
        private _dataSynchronizationService: DataSynchronizerService,
        private _toastService: ToastService,
        private _dialog: MatDialog
    ) {
        this._subscriptions.push(
            this._dbService.getDatabase().subscribe({
                next: (data) => {
                    this._db = data
                }
            }))
    }

    async synchronize(from: string = 'not-provided') {

        if (this._synchronizationStatus.value.isSynchronizing) {
            console.warn("[Synchronization Service]: Sync (from " + from + ") is in progress. Can't sync right now.");
            return;
        }
        const toPaginate: any = []
        this._tables.forEach(table => {
            toPaginate.push({ table: table, allPages: 0, currentPage: 0 })
        })

        this._synchronizationStatus.next({ error: null, isSynchronizing: true, hasSuccessfulSynchronize: false, data: null, changeOccured: false, currentPage: 0, allPages: 0 })

        if (this._db == null) { console.error("[Synchronization Service]: Database is not loaded yet."); return; }

        // Get latest sync to check if it is a first synchronization
        const { lastSync, version } = await this._getLastSync()
        this._httpClient.post(
            API_HOST + 'account/synchronize',
            await this._getRequestBody(1, toPaginate))
            .pipe(take(1))
            .subscribe({
                next: async (response: APIResponse) => {

                    if (response.success == false) {
                        this._toastService.warning(response.message)
                        this._processError(null)
                        return
                    }

                    // If its not a first version and version is diffrent
                    if (version != '0.0.0' && version != response.data.version) {
                        console.warn(['[Synchronization Service]: Application is updating'])
                        this._showAppUpdateDialog()

                        this._synchronizationStatus.next({ error: null, isSynchronizing: false, hasSuccessfulSynchronize: false, lastSyncAt: lastSync, changeOccured: false, currentPage: 1, allPages: 1 })

                        const dbs = await Dexie.getDatabaseNames()

                        dbs.forEach(db => {
                            const deleteRequest = indexedDB.deleteDatabase(db);

                            deleteRequest.onsuccess = () => {
                                console.log(`[Synchronization Service]: Updating application databases: ${db}`);
                            };

                            deleteRequest.onerror = (error) => {
                                console.error(`[Synchronization Service]: Error deleting database ${db}:`, error);
                            };
                        })

                        setTimeout(() => {
                            window.location.reload()
                        }, 3000)

                        return;
                    }

                    this._setMigrationVersion(response.data.version)

                    let changeOccured = false;
                    if (this._db == null) { return; }
                    if (response.data != null) {

                        // Set biggest page
                        let biggestPage = 0;
                        toPaginate.forEach(paginator => {
                            if (response.data[paginator.table].totalPages > biggestPage) { biggestPage = response.data[paginator.table].totalPages }
                            paginator.totalPages = response.data[paginator.table].totalPages;
                            paginator.currentPage = response.data[paginator.table].currentPage;
                        })

                        this._synchronizationStatus.next({ error: null, isSynchronizing: true, hasSuccessfulSynchronize: false, changeOccured: false, currentPage: 1, allPages: biggestPage })

                        changeOccured = true;
                        console.log(`[Synchronization Service]: Detected ${biggestPage} pages to import.`)
                        for (let i = 1; i <= biggestPage; i++) {

                            let hasError = false;
                            let err = null;
                            if (i > 1) {
                                await this._getNewSyncPage(i, toPaginate).then((nextResponse: APIResponse) => {

                                    if (nextResponse.success == false) {
                                        this._toastService.warning(nextResponse.message)
                                        this._processError(null)
                                        return
                                    }
                                    response.data = nextResponse.data
                                })
                                    .catch((e) => {
                                        hasError = true;
                                        err = e;
                                    })
                            }

                            if (hasError) {
                                this._synchronizationStatus.next({ error: err, isSynchronizing: false, hasSuccessfulSynchronize: false, changeOccured: false, currentPage: i, allPages: biggestPage })
                                return;
                            }

                            toPaginate.map(x => x.table).forEach(async (table: any) => {

                                if (typeof response.data[table] != 'undefined' && response.data[table].items.length > 0) {

                                    // Save data to database
                                    let temp: any = [];
                                    // data[table].items = Utils.HTMLEntityDecodeArray(data[table].items);
                                    response.data[table].items.forEach((row: any) => {
                                        temp.push(row);
                                    })

                                    //Take first part and check if there is more pages
                                    if (temp.length > 0) {
                                        console.log(`SYNC: Pushing ${temp.length} rows to ${table} table.`)
                                        this._synchronizationStatus.next({ error: null, isSynchronizing: true, hasSuccessfulSynchronize: false, changeOccured: false, currentPage: i, allPages: biggestPage })

                                        // Update data in database
                                        await this._db[table].bulkPut(temp).catch((ex: any) => {
                                            console.warn(`Proceed: ${temp.length}`)
                                            if (ex) {
                                                console.warn(`Errors: ${ex.failures}`)
                                            }
                                            return;
                                        })

                                        this._dataSynchronizationService.next(table, temp)
                                    }
                                }
                            });
                        }
                    }

                    this._setLastSyncDate(response.data.lastSyncAt)
                    this._synchronizationStatus.next({ error: null, isSynchronizing: true, hasSuccessfulSynchronize: true, changeOccured: changeOccured })
                    let t2 = new Date().getTime();
                    // console.warn(`Sync time: ${t2 - t1}ms (${((t2 - t1) / 1000).toFixed(4)} sec)`);

                    setTimeout(() => {
                        this._synchronizationStatus.next({ error: null, isSynchronizing: false, hasSuccessfulSynchronize: false, changeOccured: false })
                    }, 100)


                },
                error: (err) => {
                    this._processError(err);
                }
            }
            )
    }

    private async _setLastSyncDate(lastSync) {
        console.warn(`[Synchronization Service] Saving last synchronization date time: ${this._db.name}-lastSync ${lastSync}`);
        await this._db.synchronizations.where("id").equals(1).modify({ lastSync: lastSync });
    }

    private async _getRequestBody(i, toPaginate) {

        let request = {
            lastSyncAt: (await this._getLastSync()).lastSync,
            deviceId: localStorage.getItem('device_id')
        }

        toPaginate.forEach(element => {

            element.currentPage = i;
            if (element.currentPage > element.totalPages) {
                element.currentPage = 0;
            }

            request[element.table] = {
                page: element.currentPage
            }
        })

        return request
    }

    private async _setMigrationVersion(version) {
        console.warn(`[Synchronization Service]: Saving migration version: ${version}`);
        await this._db.synchronizations.where("id").equals(1).modify({ version: version });
    }

    private async _getLastSync() {
        // Get last sync date from user database.
        let sync = await this._db.synchronizations.get({ id: 1 })

        // If not exists or lastSync key changed try to bulk add record
        if (sync == null || typeof sync.lastSync == 'undefined') {
            sync = new DbSynchronization(1, "0000-00-00 00:00:00.000000", "0.0.0");
            await this._db.synchronizations.put(sync);
        }

        console.warn(`Getting last synchronization date time: ${this._db.name}-lastSync ${sync.lastSync}`);
        return {
            lastSync: sync.lastSync,
            version: sync.version
        };
    }

    private async _getNewSyncPage(i, toPaginate) {
        return firstValueFrom(
            this._httpClient.post(
                API_HOST + 'account/synchronize',
                await this._getRequestBody(i, toPaginate)
            )
        )
    }

    private _processError(err) {
        // this.handleError(err);
        console.warn("[Synchronization Service] Failed to synchronize.")
        // this.newSynchronize("Invoked from handling error.");
        this._synchronizationStatus.next({ error: err, isSynchronizing: false, hasSuccessfulSynchronize: false, changeOccured: false })
    }
    private _showAppUpdateDialog() {

        this._dialog.open(LoadingDialogComponent, {
            data: {
                title: 'Aktualizacja aplikacji',
                description: 'Trwa aktualizacja aplikacji. Proszę czekać.'
            },
            disableClose: true
        })

    }
}


import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { API_HOST } from "environments/environment"

@Injectable({
    providedIn: 'root'
})
export class NBPApiService {

    constructor(private http: HttpClient) { }

    public getExchangeRate(date, currency) {
        return this.http.get(API_HOST + `nbp/exchange-rate?date=${date}&currency=${currency}`)
    }
}
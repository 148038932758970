import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, switchMap, take, finalize, throwError } from "rxjs";
import { Router } from "@angular/router";
import { AuthService } from "shared/services/auth.service";
import { ObjectUtils } from "fakturnia-shared";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  private _isRefreshing = false;

  constructor(
    private _authService: AuthService,
    private _router:Router, 
  ) 
  {
    
  }

  // Intercept request
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if(!request) return next.handle(request)
    if(request instanceof(HttpErrorResponse)) return throwError(() => request);
    // Do not proceed when logout
    if(request.url.includes('logout')) return next.handle(request)


    return next.handle(request).pipe(
      catchError((error) => {
        
        // Must include `TOKEN_EXPIRED` status
        const errorMessage = ObjectUtils.getNestedValue(error,"error.message")
      
        if(errorMessage == 'TOKEN_EXPIRED' && error.status == 401 )  {
          console.log("errorMessage: ", errorMessage)
          return this._tryGetRefreshToken(request, error, next);
        }

        throw new HttpErrorResponse(error);
             
      })
    );
  }
  
  private _tryGetRefreshToken(request: HttpRequest<any>, error,  next: HttpHandler) {


    if (this._isRefreshing){
      return next.handle(request)
    }
     
    // Toggle is refreshing 
    this._isRefreshing = true;

    if (!this._authService.isLoggedIn()) {
      return next.handle(request)
    }

    // Get new access and refresh token
    return this._authService.getRefreshToken().pipe(
      switchMap(() => {
        this._isRefreshing = false;
        let headers = request.headers
        .set('x-access-token', this._authService.accessToken || '')
        .set('x-session-id', this._authService.sessionId || '');
        request = request.clone({
            withCredentials: true,
            headers: headers
        })
        this._isRefreshing = false
        return next.handle(request);
      }),
      catchError((error) => {
        this._isRefreshing = false
        return throwError(() => error);
      })
    );

  }

}


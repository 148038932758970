import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[quantity]'
})
export class QuantityDirective {
  
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: KeyboardEvent) {
    const input = this.el.nativeElement;
    let value = input.value.toString();

    // Replace comma with dot for international compatibility
    value = value.replace(',', '.');

    // Prevent starting with a dot (convert ".1234" to "0.1234")
    if (value.startsWith('.')) {
      value = '0' + value;
    }

    // Allow only valid float numbers (numbers and a single dot)
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (!regex.test(value)) {
      value = value.replace(/[^0-9.]/g, '');
    }

    // Ensure only one dot is allowed
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts[1]; // Keep only the first dot
    }

    // Update the input value but don't convert to float yet to allow typing.
    input.value = value;
  }

  // Parse the input to a float only when the user leaves the input field
  @HostListener('blur', ['$event']) onBlur(event: KeyboardEvent) {
    const input = this.el.nativeElement;
    let value = input.value.toString();

    // Convert the value to float once the user finishes typing
    if (value !== '' && !isNaN(parseFloat(value))) {
      input.value = parseFloat(value).toString();
    }
  }

  // Prevent invalid characters from being typed (only allow numbers, dots, and commas)
  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const allowedChars = /[0-9,.]/;

    // Block any character that is not a number, comma, or dot
    if (!allowedChars.test(event.key)) {
      event.preventDefault();
    }
  }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { JoinUsComponent } from 'shared/components/join-us/join-us.component';
import { ReadMoreComponent } from 'shared/components/read-more/read-more.component';

@Component({
  standalone: true,
  imports: [CommonModule, JoinUsComponent, ReadMoreComponent, RouterLink],
  selector: 'app-functionalities',
  templateUrl: './functionalities.component.html',
  styleUrls: ['./functionalities.component.scss']
})
export class FunctionalitiesComponent {

  documents = [
    'Faktura VAT', 
    'Faktura zaliczkowa', 
    'Faktura końcowa',
    'Faktura korygująca',
    'Faktura VAT marża',
    'Faktura proforma',
    'Faktura proforma bez VAT'
  ]
}

<h1 style="display:none;">Bezpłatny program do wystawiania faktur przez przeglądarkę Fakturnia.pl to narzędzie, które
  umożliwia łatwe i szybkie wystawianie faktur online.</h1>

<section class="bg-white dark:bg-slate-800">
  <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
    <div class="grid grid-cols-2 gap-8 place-items-center text-xl">
      <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">
        <h1 class="text-5xl mb-5">
          Twórz dokumenty<br />
          <div class="text-2xl" >niezbędne w codziennej pracy</div>
        </h1>
      </div>
      <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div class="flex items-center" *ngFor="let document of documents">
            <div class="flex items-center justify-center min-w-4 min-h-4 max-h-4 maw-w-4 bg-primary text-white text-base rounded-full mr-2">
              <mat-icon class="text-white" [svgIcon]="'tabler_outline:check'"></mat-icon>
            </div> {{document}}
          </div>

          <div class="text-2xl">... i inne</div>
        </div>
      </div>

      <div class="col-span-2 mt-12 lg:mt-24">
        <img src="/assets/images/MacBook_Pro_16_v3_wystaw_fakture_fakturnia_pl.png" class="max-w-160 lg:max-w-full" alt="Wystaw fakturę w programie Fakturnia.pl">
      </div>
    </div>
  </div>
</section>

<join-us></join-us>

<section class="bg-white dark:bg-slate-800">
  <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
    <div class="grid grid-cols-2 gap-8 place-items-center">

      <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">

        <div class="flex">

          <div
            class="flex items-center justify-center min-w-8 min-h-8 max-h-8 maw-w-8 bg-primary text-white text-3xl rounded-full">
            <i class="ti ti-check"></i>
          </div>

          <div class="pl-4">
            <h4 class="mb-1">
              Podgląd dokumentu na żywo
            </h4>

            <p class="text-body-secondary mb-6">
              Podczas wypełniania faktury widzisz dokładnie jak wygląda dokument.
            </p>
          </div>

        </div>

        <div class="flex">

          <div
            class="flex items-center justify-center min-w-8 min-h-8 max-h-8 maw-w-8 bg-primary text-white text-3xl rounded-full">
            <i class="ti ti-check"></i>
          </div>

          <div class="pl-4">
            <h4 class="mb-1">
              Wyszukiwarka GUS
            </h4>

            <p class="text-body-secondary mb-6">
              Jednym kliknięciem pobieraj dane klientów z Bazy Głównego Urzędu Statystycznego.
            </p>
          </div>

        </div>


        <div class="flex">

          <div
            class="flex items-center justify-center min-w-8 min-h-8 max-h-8 maw-w-8 bg-primary text-white text-3xl rounded-full">
            <i class="ti ti-check"></i>
          </div>

          <div class="pl-4">
            <h4 class="mb-1">
              Szybkie udostępnianie
            </h4>

            <p class="text-body-secondary mb-6">
              Wysyłaj dokumenty na adres e-mail bezpośrednio przez aplikację.
            </p>
          </div>
        </div>
      </div>

      <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">
        <div class="img-skewed img-skewed-start">
          <picture>
            <img src="/assets/images/MacBook_Pro_16_v3_podglad_faktury_fakturnia_pl.png"
              class="screenshot img-fluid img-skewed-item" alt="Podgląd faktury w aplikacji Fakturnia.pl">
          </picture>
        </div>
      </div>

    </div>
  </div>
</section>
<section class="bg-white dark:bg-slate-800">
  <div class="max-w-360 w-full mx-auto px-2 lg:px-12 py-12 lg:py-28">
    <div class="grid grid-cols-2 gap-8 place-items-center">

      <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">
        <div class="img-skewed img-skewed-start">
          <picture>
            <img src="/assets/images/MacBook_Pro_16_v3_dostosuj_dokument_fakturnia_pl.png"
            class="screenshot img-fluid img-skewed-item" alt="Dostosuj dokument w programie do faktur Fakturnia.pl">
          </picture>
        </div>
      </div>

      <div class="col-span-2 lg:col-span-1 sm:w-140 md:w-160 lg:w-full px-4 lg:px-8 mx-auto">

        <div class="flex">

          <div
            class="flex items-center justify-center min-w-8 min-h-8 max-h-8 maw-w-8 bg-primary text-white text-3xl rounded-full">
            <i class="ti ti-check"></i>
          </div>

          <div class="pl-4">
            <h4 class="mb-1">
              Odbiorca na fakturze
            </h4>

            <p class="text-body-secondary mb-6">
              Masz możliwość dodania odbiorcy na dokumencie.
            </p>
          </div>

        </div>

        <div class="flex">

          <div
            class="flex items-center justify-center min-w-8 min-h-8 max-h-8 maw-w-8 bg-primary text-white text-3xl rounded-full">
            <i class="ti ti-check"></i>
          </div>

          <div class="pl-4">
            <h4 class="mb-1">
              Rabat na fakturze
            </h4>

            <p class="text-body-secondary mb-6">
              Możesz dodać rabat procentowy do wybranej pozycji.
            </p>
          </div>

        </div>


        <div class="flex">

          <div
            class="flex items-center justify-center min-w-8 min-h-8 max-h-8 maw-w-8 bg-primary text-white text-3xl rounded-full">
            <i class="ti ti-check"></i>
          </div>

          <div class="pl-4">
            <h4 class="mb-1">
              Rejestr aktywności
            </h4>

            <p class="text-body-secondary mb-6">
              Program zapisuje historię zmian wprowadzanych na dokumentach.
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<read-more [path]="'/funkcjonalnosci-aplikacji/podglad-faktury'" [text]="'Podgląd faktury'"></read-more>
<div class="dropdown">
    <span class="dropdown-header">{{label}}</span>
    <div class="dropdown-toggle gtu-btn" [class]="toggleClass" [ngClass]="{ 'show': isOpen }" type="button" (click)="toggleDropdown()">
        <ng-container *ngIf="type == 'input'">
          <input type="text" [value]="selectedOption" decimalFormatter (change)="selectOption($event.target.value)"/>
        </ng-container>

        <ng-container *ngIf="type == 'select'">
          {{ selectedOption || placeholder }}
        </ng-container>
    </div>
    <ul class="dropdown-menu" [ngClass]="{ 'show': isOpen, 'hidden': options?.length == 0 }">
        <li [ngClass]="{ 'selected': selectedOption === '' }" (click)="selectOption('')">
           Brak
        </li>
      <li *ngFor="let option of options" [ngClass]="{ 'selected': option.id === selectedOption }" (click)="selectOption(option.id)">
        <span class="fs-17" style="font-weight:400">{{ option.id }}</span> <br/>
        <span class="fs-13">{{ option.text }}</span>
      </li>
    </ul>
  </div>
import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { APIResponse } from 'fakturnia-shared';
import { ApiService } from 'shared/services/api.service';
import { Utils } from 'fakturnia-shared';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

@Component({
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatSelectModule, MatButtonModule, MatIconModule],
  selector: 'app-create-or-update-announcement',
  templateUrl: './create-or-update-announcement.component.html',
  styleUrls: ['./create-or-update-announcement.component.scss']
})
export class CreateOrUpdateAnnouncementComponent implements OnDestroy {
 
  
  mode: 'create' | 'update' = 'create'
  private _subscriptions:Subscription[] = [];
  id = null
  form = new FormGroup({
    title: new FormControl(''),
    description: new FormControl(''),
    isActive: new FormControl(false),
  })

  constructor(
    private _apiService:ApiService,
    private _route:ActivatedRoute,
    private _router:Router
  ) {
    this._subscriptions.push(this._route.params.subscribe(
      (data:any)=> {
        if(data != null)
        {
          if(!Utils.isNullOrEmpty(data.id))
          {
            if(data.id == 'new') return
            this.id = data.id
            this.mode = 'update'
            this._getAnnouncement(data.id);
          }
        }
      }
    ))
  }

  save() {
    this._apiService.updateAnnouncement(this.id, this.form.value).pipe(take(1)).subscribe({
      next: (response:APIResponse) => {
        if(response.success) {
          this._router.navigateByUrl('/admin/announcements')
        }
      }
    })
  }

  create() {
    this._apiService.createAnnouncement(this.form.value).pipe(take(1)).subscribe({
      next: (response:APIResponse) => {
        if(response.success) {
          this._router.navigateByUrl('/admin/announcements')
        }
      }
    })
  }
  
  remove(){
    const a =  alert('Potwierdzenie')
    this.delete()
  }

  delete() {
    this._apiService.deleteAnnouncement(this.id).pipe(take(1)).subscribe({
      next: (response:APIResponse) => {
        if(response.success) {
          this._router.navigateByUrl('/admin/announcements')
        }
      }
    })
  }
  private _getAnnouncement(id) {
    this._apiService.getAnnouncementAsAdmin(id).pipe(take(1)).subscribe({
      next: (response:APIResponse) => {
        if(response.success) {

          this.form.controls.title.setValue(response.data.title)
          this.form.controls.description.setValue(response.data.description)
          this.form.controls.isActive.setValue(response.data.isActive)
        }
      }
    })
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }
}

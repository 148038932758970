<div class="w-full max-w-320 mx-auto p-4 lg:p-8">

    <div class="mb-2 sm:mb-0 flex justify-end space-x-2">
        <button mat-flat-button [routerLink]="'/konto/produkty/utworz'">
            <mat-icon [svgIcon]="'tabler_outline:plus'"></mat-icon>
            <span class="ml-2">Dodaj produkt</span>
        </button>
    </div>

    <div>
        <products-table [filters]="filters" class="mb-12 block"></products-table>
    </div>
</div>

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer'

@Component({
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {

  constructor() {
    pdfDefaultOptions.assetsFolder = 'assets/scripts/pdfjs';

  }
}

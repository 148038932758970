<ng-container class="mb-4" *ngIf="announcements.length > 0">

  <h2 class="text-3xl font-bold mb-3">Ogłoszenia</h2>

  <!-- Listing of announcements -->
  <div class="relative bg-card shadow-lg p-4 mb-4 rounded-lg border-primary text-black dark:text-white border-l-4 dark:bg-primary dark:border-primary-300" *ngFor="let announcement of announcements">
    <div class="flex items-center">
      <mat-icon class="text-black dark:text-white w-14 h-14" [svgIcon]="'tabler_outline:bell-ringing'"></mat-icon>
      
      <div class="pl-4">
        <h3 class="text-xl font-medium" [innerHTML]="announcement.title"></h3>
        <div class="mt-2 text-base" [innerHTML]="announcement.description">
        </div>
        <button mat-icon-button (click)="read(announcement.id)"  class="absolute top-2 right-2">
            <mat-icon class="text-black dark:text-white" [svgIcon]="'tabler_outline:x'"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>

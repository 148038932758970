<div class="container p-4 pt-0">
    <h3>Użytkownicy ({{pagination.count}})</h3>
    <mat-form-field >
        <mat-label>Szukaj</mat-label>
        <input [formControl]="searchInputControl" type="text" matInput>
    </mat-form-field>
    <table class="table table-striped">
      <thead class="table-heading">
        <tr>
          <th>#</th>
          <th>Imię i nazwisko</th>
          <th>Adres e-mail</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users; let i = index">
          <td>{{(pagination.currentPage -1) * pagination.itemsPerPage + i+1}}</td>
          <td>{{user.firstName}} {{user.lastName}}</td>
          <td>{{user.emailAddress}}</td>
          <td><a [routerLink]="user._id">Pokaz</a></td>
        </tr>
        
      </tbody>
    </table>
    <pagination class="flex items-center" [currentPage]="pagination.currentPage" [totalPages]="pagination.pages" (onPageChange)="onPageChange($event)"></pagination>
  </div>
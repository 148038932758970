
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { API_HOST } from "environments/environment"

@Injectable({
    providedIn: 'root'
})
export class ProductsApiService {

    constructor(private http: HttpClient) { }

    public create(data:any) {
        data = this._prepareProduct(data)
        return this.http.post(API_HOST + 'products', data)
    }

    public update(clientId, data:any) {
        data = this._prepareProduct(data)
        return this.http.put(API_HOST + `products/${clientId}`, data)
    }
    
    public delete(id:string) {
        return this.http.delete(API_HOST + `products/${id}`)
    }

    /**
     * Prepares product numbers to decimal values
     * @param product 
     * @returns 
     */
    private _prepareProduct(product) {

        product.quantity = parseFloat(product.quantity)
        product.amountOfVat = Number(product.amountOfVat.toFixed(2))
        product.netPrice = Number(product.netPrice.toFixed(2))
        product.netValue = Number(product.netValue.toFixed(2))
        product.grossValue = Number(product.grossValue.toFixed(2))
        product.taxSymbol = typeof product.vatRate == 'number' ? '%' : ''

        if(typeof product._id != 'undefined') delete product._id
        if(typeof product.isDeleted != 'undefined') delete product.isDeleted
        if(typeof product.gtu != 'undefined') delete product.gtu
        if(typeof product.discount != 'undefined') delete product.discount
        
        return product
    }

}
<div class="bg-card rounded-xl shadow-lg">
    <ng-container *ngFor="let element of data; let i = index;">
        <div class="invoice-card-header grid grid-cols-12 p-4" *ngIf="i == 0">
            <div class="col-span-9">
                <div class="header-text">Lista przychodów</div>
                <div class="current-month">{{element.monthName}} {{element.year}}</div>    
            </div>
            <div class="col-span-3">
                <div class="header-text" >
                    <span class="flex items-center justify-end cursor-pointer" [matMenuTriggerFor]="optionMenu">
                        {{selectedFieldId.text}}
                        <mat-icon class="w-3 text-white" [svgIcon]="'mat_solid:arrow_drop_down'"></mat-icon>
                    </span>
                </div>
                <mat-menu #optionMenu="matMenu" class="max-h-100 pb-0">
                    <div class="sticky top-0 z-10 -mt-3 bg-neutral-100 left-0 w-full px-4 pt-2 pb-2 dark:bg-slate-900 text-sm border-b">
                      Wybierz wartość
                    </div>
                    <button *ngFor="let field of countFieldOptions" mat-menu-item (click)="changeOption(field)">
                      <mat-icon *ngIf="field.value == selectedFieldId.value" [svgIcon]="'tabler_outline:check'"></mat-icon>
                      {{field.text}}
                    </button>
                </mat-menu>
                <div class="current-month-value text-end">
                    <div *ngFor="let sum of element.summary">
                        {{toDecimal(sum.summary)}} <span class="text-sm text-gray-200">{{sum.currency}}</span>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="element.invoices.length > 0">
            <div class="grid grid-cols-2 bg-neutral-100 dark:bg-slate-700 gap-6 border-t-4  px-2 sm:px-4 py-4 cursor-pointer"  *ngIf="i > 0" (click)="element.toggleExpand()">
                <div class="month-name font-medium">
                    {{element.monthName}} {{element.year}} <span class="text-sm ml-2 text-gray-400">({{element.invoices.length}})</span>
                </div>
                <div class="month-value text-end">
                    <div *ngFor="let sum of element.summary">
                        {{toDecimal(sum.summary)}} <span class="text-sm text-gray-300">{{sum.currency}}</span>
                    </div>
                </div>
            </div>
            <!-- Hide months without data -->
            <ng-container *ngIf="element.expanded">
                <div class="grid grid-cols-12 text-sm text-secondary font-semibold p-4 dark:bg-slate-950" *ngIf="element.invoices.length > 0">
                    <div class="col-span-7">
                        Dokument
                    </div>
                    <div class="col-span-5 text-end">
                        {{selectedFieldId.text}}
                    </div>
                </div>
                <div [routerLink]="'/konto/dokumenty/' + invoice._id + '/podglad'" class="invoice-card-table-row p-4 grid grid-cols-12 border-t" *ngFor="let invoice of element.invoices">
                    <div class="col-span-7">
                        <div class="invoice-buyer" *ngIf="!invoice.buyer">
                            Brak nabywcy
                        </div>
                        <div class="invoice-buyer" *ngIf="invoice.buyer.type == 'company'">
                            {{invoice.buyer.name}}
                        </div>
                        <div class="invoice-buyer" *ngIf="invoice.buyer.type == 'person'">
                            {{invoice.buyer.firstName}}  {{invoice.buyer.lastName}}
                        </div>
                        <div class="text-md text-secondary hover:text-primary">
                            {{invoice.invoiceName}}
                        </div>
                    </div>
                    <div class="col-span-5 text-end">
                       {{toDecimal(invoice[selectedFieldId.value])}} <span class="text-sm text-gray-300">{{invoice.currency}}</span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        
        
    </ng-container>
    
</div>

<div class="relative flex flex-col w-full min-h-full max-h-full">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-gray-50  border-b dark:bg-gray-900">
        <div class="text-2xl font-medium">
            Pobierz wiele
        </div>
        <button mat-icon-button [matDialogClose]="undefined">
            <mat-icon class="dark:text-white" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>

    <!-- Content -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 overflow-y-scroll sm:overflow-y-auto p-6">

            <div class="text-gray-600 dark:text-gray-300 mb-2">
                Pobierz wystawione faktury z wybranego miesiąca
            </div>

            <div class="grid grid-rows-1 md:grid-cols-2 md:gap-x-6">
                <div>
                    <mat-form-field class="w-full">
                        <mat-label>Okres rozliczeniowy</mat-label>
                        <mat-select [(ngModel)]="selectedRange">
                            <mat-option [value]="option" *ngFor="let option of rangeOptions">{{option.month}}
                                {{option.year}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
        
                <div>
                    <mat-form-field class="w-full">
                        <mat-label>Typy dokumentów</mat-label>
                        <mat-select [(ngModel)]="selectedType">
                            <mat-option value="">Wszystkie typy</mat-option>
                            <mat-option value="allRecorded">Wszystkie bez proform</mat-option>
                            <mat-option [value]="type" *ngFor="let type of invoiceTypes">{{type.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
        
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t z-10 bg-gray-50 dark:bg-gray-900">
        <div class="flex items-end">
            <div class="ml-auto">
                <button mat-flat-button color="primary" (click)="print()">Pobierz dokumenty</button>
                <button mat-button (click)="close()" class="ml-2">Zamknij</button>
            </div>
        </div>
    </div>
</div>




import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Company, IProduct, Invoice } from "fakturnia-shared"
import { API_HOST } from "environments/environment"

@Injectable({
    providedIn: 'root'
})
export class DocumentsApiService {

    constructor(private _http: HttpClient) { }

    public sendDocumentByEmail(documentId, receivers, sendCopyToUser) {
        return this._http.post(API_HOST + `documents/${documentId}/send-by-email`, {
            receivers: receivers,
            sendCopyToUser: sendCopyToUser
        })
    }

    public create(document:Invoice) {
        const data = this._prepareDocumentDTO(document)
        return this._http.post(API_HOST + 'documents', data)
    }

    public update(documentId, document:any) {
        const data = this._prepareDocumentDTO(document)
        return this._http.put(API_HOST + `documents/${documentId}`, data)
    }

    public delete(documentId) {
        return this._http.delete(API_HOST + `documents/${documentId}`)
    }
    
    public markDocumentsAsPaidOrTemplate(ids, isPaid) {
        return this._http.patch(API_HOST + 'documents/mark-as-paid-or-template', {
            _ids: ids,
            isPaid: isPaid
        })
    }

    public markDocumentsAsPrinted(id) {
        return this._http.patch(API_HOST + `documents/${id}/mark-as-printed`,null)
    }
    
    public markDocumentsAsDownloaded(id) {
        return this._http.patch(API_HOST + `documents/${id}/mark-as-downloaded`,null)
    }

    private _prepareDocumentDTO(document:Invoice) {

        // If its a invoice without TAX set vat rate to 0%
        const documentsWithoutTax = ['FakturaProformaBezVAT', 'FakturaVATMarza', 'FakturaVATMarzaProforma']
        if(documentsWithoutTax.includes(document.documentType)) {
            document.products.forEach(product=> {
                product.setTax(0)
            })
            document.count()
        }

        const dto:any = {
            issueDate: document.issueDateHeader.value,
            // Headers
            issueDateHeader: document.issueDateHeader,
            placeHeader: document.placeHeader,
            subHeader: document.subHeader,
            additionalPaymentDateHeader: document.additionalPaymentDateHeader,

            documentNumber: document.invoiceName,
            documentType: document.documentType,
            prepaymentIds: document.prepaymentIds,
            correctedInvoiceId: document.correctedInvoiceId || null,
            grossSum:  Number(document.grossSum.toFixed(2)),
            
            hasReceiver: document.hasReceiver,
            receiver: document.receiver,

            hasComments: document.hasComments,
            comments: document.comments,

            exchangeRate: document.exchangeRate,

            hasGTU: document.hasGTU,
            hasDiscount: document.hasDiscount,
            
            vatAccountNumber: document.vatAccountNumber,
            marginProcedureId: document.marginProcedureId,
            buyer: document.buyer,
            seller: document.seller,
            currency: document.currency,
            bankAccountNumber: document.bankAccountNumber,
            paymentStatus: document.paymentStatus,
            paymentMethod: document.paymentMethod,
            paymentDeadline: document.paymentDeadline,
            
            signatures: document.signatures,

            hasPaymentDescription: document.hasPaymentDescription,
            paymentDescription: document.paymentDescription,
        }

        dto.seller = this._prepareCompanyDTO(document.seller)
        delete dto.seller._id

        dto.buyer = this._prepareCompanyDTO(document.buyer)

        dto.receiver = this._prepareCompanyDTO(document.receiver)

        // Map products to avoid circular error
        dto.products = this._prepareProductsDTO(document)

        return dto
    }

    private _prepareCompanyDTO(company:Company) {
        return {
            _id: company._id,
            type: company.type,
            name: company.name,
            firstName: company.firstName,
            lastName: company.lastName,
            nip: company.nip,
            regon: company.regon,
            krs: company.krs,
            bdo: company.bdo,
            pesel: company.pesel,
            idNumber: company.idNumber,
            street: company.street,
            postalCode: company.postalCode,
            city: company.city,
            country: company.country,
            phoneNumber: company.phoneNumber,
            fax: company.fax,
            email: company.email,
            www: company.www,
            description: company.description,
            fieldsToShow: company.fieldsToShow
        }
    }

    private _prepareProductsDTO(document:Invoice) {
        return document.products.map((product)=> ({
            _id: product._id,
            name: product.name,
            // gtu: product.gtu,
            discount: Number(product.discount),
            unitOfMeasure: product.unitOfMeasure,
            quantity: Number(product.quantity), 
            netPrice: Number(product.netPrice.toFixed(2)),
            netValue: Number(product.netValue.toFixed(2)),
            grossValue: Number(product.grossValue.toFixed(2)), 
            amountOfVat: Number(product.amountOfVat.toFixed(2)),
            vatRate: product.vatRate,
            taxSymbol: product.taxSymbol 
        }))
    }

    // public delete(id:string) {
    //     return this.http.delete(API_HOST + `clients/${id}`)
    // }

    // public deleteMany(_ids:string[]) {
    //     return this.http.post(API_HOST + `clients/delete-many`, { _ids: _ids })
    // }
}
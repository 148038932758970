import { ComponentPortal, Portal } from "@angular/cdk/portal";
import { Injectable, InjectionToken, Injector, OnDestroy } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { Subject, Subscription } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class PanelService implements OnDestroy {

    private _subscriptions: Subscription[] = [];
    panel: MatDrawer;
    currentPortal;

    private activePortal$ = new Subject<Portal<any>>();
    private hasActivePortal$ = new Subject<boolean>();

    readonly hasPortal = this.hasActivePortal$.asObservable();
    readonly portal$ = this.activePortal$.asObservable();

    constructor() { }

    /** Opens the panel with optionally a portal to be set. */
    async open(portal?: Portal<any>) {

       
        const p = new Promise(async (resolve) => {
            this.hasActivePortal$.next(false);
            if (this.panel.opened) { resolve(true); return; }

            await this.panel.open()
            this.hasActivePortal$.next(true);

            resolve(true)
        })

        p.then((res) => {
            if (portal) {
                this.setPortal(portal)
            }
        })

    }

    async setPortal(portal) {
        this.currentPortal = portal;
        this.activePortal$.next(this.currentPortal);
        this.hasActivePortal$.next(true);
    }

    setPanel(panel)
    {
      this.panel = panel;
      this._subscriptions.forEach(sub=>sub.unsubscribe())
    }

    /** Closes the panel. */
    close() {
        if(!this.panel) return
        this.hasActivePortal$.next(false);
        return this.panel.close();
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe());
    }
}
export const DATA_INJECTION_TOKEN = new InjectionToken<string>('DATA_INJECTION_TOKEN');

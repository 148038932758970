import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  selector: 'pagination',
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {

  @Input('currentPage') currentPage = 1;
  @Input('totalPages') totalPages = 1;
  @Output('onPageChange') onPageChange:EventEmitter<number> = new EventEmitter();

  private maxPages = 9;
  pages:any = [];

  constructor()
  {
    this.createListOfPages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['currentPage'] || changes['totalPages'])
    {
      this.createListOfPages();
    }
  }

  createListOfPages()
  {
    if(this.totalPages == 0) { this.totalPages = 1}
    this.pages = [];
    if(this.totalPages > this.maxPages && this.currentPage > 4)
    {
      for(let i=this.currentPage-4;i<=this.currentPage+4;i++)
      {
        this.pages.push(i);
        if(i == this.totalPages) { return;}
      }
    }
    else {
      for(let i=1;i<=this.maxPages;i++)
      {
        this.pages.push(i);
        if(i == this.totalPages) { return;}
      }
    }
  }

  goto(page)
  {
    this.onPageChange.emit(page);
  }

  next()
  {
    if(this.currentPage != this.totalPages)  this.goto(this.currentPage+ 1)
  }

  prev()
  {
    if(this.currentPage != 1)  this.goto(this.currentPage - 1)

  }
}

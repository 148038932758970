<div class="relative flex flex-col w-full min-h-full max-h-full">
    
    <!-- Header and body in single because its flex-col for footer -->
    <div class="flex">
        <div class="w-26 p-8 items-center" *ngIf="data.showIcon">
            <div class="icon-container" [ngClass]="data?.type">
                <mat-icon *ngIf="data?.type == 'danger'">warning_amber</mat-icon>
                <mat-icon *ngIf="data?.type == 'warning' || data?.type == 'error'">error</mat-icon>
                <mat-icon *ngIf="data?.type == 'info'">notifications_none</mat-icon>
                <mat-icon *ngIf="data?.type == 'edit'">edit</mat-icon>
                <mat-icon *ngIf="data?.type == 'success'">check_circle</mat-icon>
            </div>
        </div>
        <div class="w-full">

            <!-- Header -->
            <div class="flex flex-0 w-full items-center justify-between pt-4 pr-6">
                <div class="md:text-2xl font-medium">
                    {{data?.title}}
                </div>
                <button mat-icon-button [matDialogClose]="undefined">
                    <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>

            <!-- Body -->
            <div class="flex flex-0 pr-6 w-full overflow-hidden">
                <div class="flex flex-col flex-auto sm:mb-18 overflow-y-scroll sm:overflow-y-auto max-w-72">
                    <p class="text-secondary" [innerHTML]="data?.message">
                    </p>
                    <div *ngIf="data?.inputs?.length > 0">
                        <form [formGroup]="form">
                            <div *ngFor="let input of data?.inputs">
                                <div [ngSwitch]="input.type">
                                    <div *ngSwitchCase="'checkbox'">
                                        <mat-checkbox [formControlName]="input.name">{{input.description}}</mat-checkbox>
                                    </div>
                                    <div *ngSwitchCase="'text'">
                                        <mat-form-field class="w-100">
                                            <mat-label>{{input.description}}</mat-label>
                                            <input [formControlName]="input.name" matInput [placeholder]="input.description"
                                                [value]="input.value">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7  border-t dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
          <div class="ml-auto">
                <button mat-flat-button *ngIf="data?.confirmText != null" (click)="close(true)" [disabled]="!form.valid" [color]="(data?.type == 'warning' || data?.type == 'error' || data?.type == 'danger') ? 'warn' : 'primary'">
                    {{data?.confirmText}}
                </button>
                <button mat-stroked-button *ngIf="data?.cancelText != null" (click)="close(false)" class="ml-2">
                    {{data?.cancelText}}
                </button>
            </div>
        </div>
    </div>
</div>

import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ErrorInterceptorProvider } from 'shared/interceptors/error.interceptor';
import { RefreshTokenInterceptorProvider } from 'shared/providers/refresh-token.provider';
import { AppInitService } from 'shared/services/app-init.service';
import { DateAdapter, MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FAKTURNIA_APP_CONFIG } from '@fakturnia/services/config/config.contants';
import { provideIcons } from 'shared/providers/icons.provider';
import { NgxSeoModule } from 'shared/modules/ngx-seo/ngx-seo.module';
import { AuthInterceptorProvider } from 'shared/interceptors/auth.interceptor';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        importProvidersFrom(HttpClientModule),
        AuthInterceptorProvider,
        ErrorInterceptorProvider,
        RefreshTokenInterceptorProvider,
        provideIcons(),
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
        ),
        importProvidersFrom(NgxSeoModule.forRoot({
            changeTitle: (title) => title,
            preserve: false,
            listenToRouteEvents: true,
        })),
        // Provide app initializer
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppInitService],
            multi: true
        },
        {
            // Disable 'theme' sanity check
            provide : MATERIAL_SANITY_CHECKS,
            useValue: {
                doctype: true,
                theme  : false,
                version: true,
            },
        },
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide : MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill',
            },
        },
        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide : FAKTURNIA_APP_CONFIG,
            useValue: {
              layout: 'modern',
              scheme: 'light',
              screens: {
                  sm: '600px',
                  md: '960px',
                  lg: '1280px',
                  xl: '1440px',
              },
              theme: 'theme-default',
              themes: [
                  {
                      id: 'theme-default',
                      name: 'Default',
                  },
              ],
          },
        },
        { provide: LOCALE_ID, useValue: 'pl' },
    ]
}

export function initializeApp(appInitService: AppInitService) {
    return (): Promise<any> => { 
      return appInitService.initializeApp();
    }
  }
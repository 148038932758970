import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { API_HOST } from "environments/environment"

@Injectable({
    providedIn: 'root'
})
export class ClientsApiService {

    constructor(private http: HttpClient) { }

    public create(data:any) {
        return this.http.post(API_HOST + 'clients', data)
    }
    
    public verifyClientCompany(taxId) {
        return this.http.get(API_HOST + `clients/verify/${taxId}`)
    }

    public update(clientId, data:any) {
        return this.http.put(API_HOST + `clients/${clientId}`, data)
    }
    
    public delete(id:string) {
        return this.http.delete(API_HOST + `clients/${id}`)
    }

    public deleteMany(_ids:string[]) {
        return this.http.post(API_HOST + `clients/delete-many`, { _ids: _ids })
    }
}
import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'dexie';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { InvoicePreviewService } from 'shared/services/invoice-preview.service';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, NgxExtendedPdfViewerModule ],
  selector: 'app-invoice-preview-dialog',
  templateUrl: './invoice-preview-dialog.component.html',
  styleUrls: ['./invoice-preview-dialog.component.scss']
})
export class InvoicePreviewDialogComponent implements OnDestroy {

  private _subscriptions: Subscription[] = []

  previewBase64 = null
  zoom = 'auto'
  constructor(
    private dialog:MatDialogRef<InvoicePreviewDialogComponent>,
    private _invoicePreviewService:InvoicePreviewService,
    private _sanitizer: DomSanitizer
    ) {
    this._subscriptions.push(this._invoicePreviewService.currentPreview.subscribe({
      next: (data) => {
        this.previewBase64 = data
      }
    }))
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  close() {
    this.dialog.close()
  }

}

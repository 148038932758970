import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { LowercaseEmailDirective } from 'shared/directives/lowercase-email.directive';
import { AuthService } from 'shared/services/auth.service';
import { emailValidator } from 'shared/validators/email.validator';

@Component({
  standalone: true,
  selector: 'login',
  imports: [CommonModule, MatButtonModule, LowercaseEmailDirective, RouterLink, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatIconModule ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  
  private _subscriptions: Subscription[] = [];

  @Input('redirect') redirect = true

  loginForm = this.formBuilder.group({
    emailAddress: ['', [Validators.required, emailValidator()]],
    password: ['',[ Validators.required, Validators.minLength(8)]]
  })

  showPassword = false;
  public isProcessing = false
  constructor(
    private authService: AuthService,
    private formBuilder:FormBuilder
    ) {

  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  login() {

    if(!this.loginForm.valid) return;
    
    this.isProcessing = true
    this.authService.loginOrRegister(
      "login",
      this.loginForm.controls.emailAddress.value,
      this.loginForm.controls.password.value,
      this.redirect
    ).subscribe({
      next: (result) => {
        if(result != true) return

        this.isProcessing = false
      }
    })
    
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Utils } from 'fakturnia-shared';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  selector: 'update-user-company-data',
  templateUrl: './update-user-company-data.component.html',
  styleUrls: ['./update-user-company-data.component.scss']
})
export class UpdateCompanyDataComponent implements OnChanges {

  @Input('company') company
  @Input('isEditable') isEditable = false
  form = new FormGroup({
    type: new FormControl('company'),
    name: new FormControl(''),
    nip: new FormControl(''),
    regon: new FormControl(''),
    street: new FormControl(''),
    postalCode: new FormControl(''),
    city: new FormControl(''),
    bdo: new FormControl(''),
    krs: new FormControl(''),
    www: new FormControl(''),
    fax: new FormControl(''),
    description: new FormControl(''),
  })

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Utils.isDefined(changes,'company.currentValue')) {
      this._updateForm(this.company)
    }
    if(Utils.isDefined(changes,'isEditable.currentValue')) {
      if(this.isEditable) this.form.enable()
      else this.form.disable()
    }
  }
  private _updateForm(company) {
    this.form.controls['type'].setValue(company.type)
    this.form.controls['name'].setValue(company.name)
    this.form.controls['nip'].setValue(company.nip)
    this.form.controls['regon'].setValue(company.regon)
    this.form.controls['street'].setValue(company.street)
    this.form.controls['postalCode'].setValue(company.postalCode)
    this.form.controls['city'].setValue(company.city)
    this.form.controls['bdo'].setValue(company.bdo)
    this.form.controls['krs'].setValue(company.krs)
    this.form.controls['www'].setValue(company.www)
    this.form.controls['fax'].setValue(company.fax)
    this.form.controls['description'].setValue(company.description)
  }

  saveChanges() {

  }
}

<div class="bg-white dark:bg-slate-800 w-full mx-auto p-8 rounded-xl">
    <mat-form-field [subscriptSizing]="'dynamic'" class="w-full">
        <mat-icon matIconPrefix [svgIcon]="'tabler_outline:mail'"></mat-icon>
        <mat-label>Adres e-mail</mat-label>
        <input type="email" matInput lowercaseEmail [formControl]="registerForm.controls.emailAddress"
            placeholder="Podaj adres e-mail">
        <mat-error
            *ngIf="registerForm.controls.emailAddress.touched && registerForm.controls.emailAddress?.errors?.invalidEmail">Nieprawidłowy
            format adresu e-mail.</mat-error>
    </mat-form-field>

    <mat-form-field [subscriptSizing]="'dynamic'" class="w-full mt-4">
        <mat-icon matIconPrefix [svgIcon]="'tabler_outline:mail'"></mat-icon>
        <mat-label>Powtórz adres e-mail</mat-label>
        <input type="email" matInput lowercaseEmail [formControl]="registerForm.controls.emailAddressRepeat"
            placeholder="Powtórz adres e-mail">
        <mat-error *ngIf="registerForm.hasError('matchFields')">Powtórzony adres e-mail jest
            inny.</mat-error>
    </mat-form-field>

    <mat-form-field [subscriptSizing]="'dynamic'" class="w-full mt-4">
        <mat-icon matIconPrefix [svgIcon]="'tabler_outline:lock'"></mat-icon>
        <mat-label>Hasło</mat-label>
        <input [type]="showPassword ? 'text' : 'password'" matInput [formControl]="registerForm.controls.password"
            placeholder="Ustaw hasło do konta">
        <button mat-icon-button matSuffix (click)="showPassword = !showPassword">
            <mat-icon>{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error  *ngIf="registerForm.controls.password.touched && registerForm.controls.password.errors?.['minlength']">Hasło
            musi mieć minimum 8 znaków.</mat-error>
    </mat-form-field>

    <div class="my-2">
        <mat-checkbox color="primary" [formControl]="registerForm.controls.rules">Akceptuje <a
                routerLink="/regulamin">regulamin</a> oraz <a routerLink="/polityka-prywatnosci">politykę
                prywatności</a> serwisu.</mat-checkbox>
        <p class="text-red-600 text-sm" *ngIf="registerForm.controls.rules.touched && registerForm.controls.rules?.errors?.required">Wymagamy akceptacji regulaminów.</p>
    </div>


    <button  mat-flat-button color="primary" [disabled]="isProcessing" class="w-full mt-4 mx-auto" (click)="register()">
        
        <mat-icon class="icn-spinner mr-2" *ngIf="isProcessing == true" [svgIcon]="'tabler_outline:loader'"></mat-icon>
        <span class="ml-2" *ngIf="isProcessing == true">Tworzenie konta</span>
        <span *ngIf="isProcessing == false" class="ml-2">Utwórz darmowe konto</span>
    </button>

</div>
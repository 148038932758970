<div class="row">
    <div class="col-6">
        <h2>
            {{mode == 'create' ? 'Nowy post' : 'Edycja postu'}}
        </h2>
    </div>
    <div class="col-6">
        <h2>Podgląd</h2>
    </div>
</div>
<!-- <mat-divider></mat-divider>
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">Administracja</li>
        <li class="breadcrumb-item" aria-current="page">Blog</li>
        <li class="breadcrumb-item active" aria-current="page">Post</li>
    </ol>
</nav> -->

<form [formGroup]="addOrUpdateForm">




    <div class="row">
        <div class="col-6">

            <div class="row mb-2">
                <div class="col-3">
                   Obrazek<br/>
                        <div (click)="imgInput.click()" class="flex items-center justify-content-center" style="background-color: #e1e1e1; width:120px; height:100px; border:2px dotted #c7c7c7; cursor:pointer; background-position: center; background-size: cover;"
                        [ngStyle]="{ 'background-image': this.addOrUpdateForm.controls.img.value != '' ? 'url('+this.addOrUpdateForm.controls.img.value+')' : '' }"
                        >
                            Zmień
                        </div>

                        <input #imgInput (change)="handleFileChange($event)" class="d-none" type="file" accept="image/jpeg, image/gif, image/png">
                
                        <div class="row mt-2">
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label>Czas czytania</mat-label>
                                    <input matInput placeholder="Czas czytania" formControlName="readingTimeInMinutes">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                <div class="col-9">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="w-100">
                                <mat-label>Tytuł</mat-label>
                                <input matInput placeholder="Tytuł" formControlName="title">
                            </mat-form-field>
                        </div>
                    </div>
        
                    
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="w-100">
                                <mat-label>Krótki opis</mat-label>
                                <input matInput placeholder="Krótki opis" formControlName="shortContent">
                            </mat-form-field>
                        </div>
                    </div>
        
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="w-100">
                                <mat-label>Adres url</mat-label>
                                <span matPrefix>&nbsp; https://fakturnia.pl/blog/ &nbsp;</span>
                                <input formControlName="url" matInput placeholder="adres url">
                                <mat-icon matSuffix>mode_edit</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>

                    
                </div>
            </div>

            

            Treść
            <quill-editor formControlName="content" (onContentChanged)="onContentChanged($event)"></quill-editor>

            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Słowa kluczowe</mat-label>
                        <mat-chip-grid #chipGrid aria-label="Enter keywords"
                            [formControl]="addOrUpdateForm.controls.keywords">
                            <mat-chip-row *ngFor="let keyword of addOrUpdateForm.controls.keywords.value"
                                (removed)="removeKeyword(keyword)">
                                {{keyword}}
                                <button matChipRemove aria-label="'remove ' + keyword">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                        </mat-chip-grid>
                        <input placeholder="Dodaj słowo..." [matChipInputFor]="chipGrid"
                            (matChipInputTokenEnd)="add($event)" />
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-slide-toggle formControlName="isActive">Widoczność</mat-slide-toggle>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <button [disabled]="!addOrUpdateForm.valid" mat-flat-button (click)="savePost()">
                        {{mode == 'create' ? 'Dodaj post' : 'Edytuj post'}}
                    </button>
                </div>
            </div>

        </div>
        <div class="col-6">
            <h3>{{addOrUpdateForm.controls.title.value}}</h3>
            <!-- <div [innerHTML]="htmlstring"></div> -->
            <div class="ql-container ql-snow" style="border-width: 0;">
                <div class="ql-editor" [innerHTML]="htmlstring">
                </div>
            </div>
        </div>



    </div>


</form>
<div class="notification-toast bg-white dark:bg-slate-700 shadow-xl">
    <div class="p-2">
        <div class="flex">
            <div class="p-2 flex-shrink-1">
                <div class="icon-container" [ngClass]="data?.status">
                    <mat-icon *ngIf="!data?.isImage" [svgIcon]="data?.icon"></mat-icon>
                    <img class="image-icon" *ngIf="data?.isImage" [src]="'/assets/img/toasts/'+data?.icon+'.png'" alt="">
                </div>
            </div>
            <div class="p-2 w-100">
                <div class="notification-toast-title">
                    {{data?.title}}
                    <div class="float-end close-btn dark:hover:bg-slate-800" (click)="close()">
                        <i class="ti ti-x"></i>
                        <mat-icon [svgIcon]="'tabler_outline:x'"></mat-icon>
                    </div>
                </div>
                <p class="notification-toast-content">
                    {{data?.message}}
                </p>
                <div class="notification-toast-date text-secondary" *ngIf="data?.withDate">
                    <date-formatter [date]="data?.createdAt" [showTime]="true"></date-formatter>
                </div>
            </div>
        </div>
    </div>
    <div class="notification-toast-footer" *ngIf="data?.buttons?.length > 0">
        <div class="flex space-x-3 h-100">
            <div *ngFor="let btn of data?.buttons" (click)="invoke(btn.action)">
                <div class="notification-toast-btn pt-2 pb-2 text-secondary">
                    {{btn.text}}
                </div>
            </div>
        </div>
    </div>
</div>
<section class="container">
    <div class="w-full mx-2 lg:w-140 lg:mx-auto">
        <div class="text-center bg-white dark:bg-slate-800 my-12 py-8 rounded-xl text-xl">
            <h2 class="text-5xl">
                Czytaj <b>dalej</b>
            </h2>
            <a [routerLink]="path">
                <div class="f-button my-2 mx-auto">
                    {{text}}
                    <mat-icon class="text-white ms-2" [svgIcon]="'tabler_outline:arrow-narrow-right'"></mat-icon>
                </div>
            </a>
        </div>
    </div>
</section>
import { inject } from "@angular/core";
import { CanActivateFn, CanActivateChildFn, Router } from "@angular/router";
import { switchMap, of } from "rxjs";
import { AuthService } from "shared/services/auth.service";


export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
  const router: Router = inject(Router);

  return inject(AuthService).isLoggedIn$.pipe(
    switchMap((authenticated:boolean) => {
      if (!authenticated )
      {
        router.navigate(['/logowanie']);
        return of(false);
      }
      
      return of(true);
  }))
}
  

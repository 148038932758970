import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, forkJoin, from, map, of, tap } from "rxjs";
import { DataSynchronizerService } from "./data-synchronization.service";
import { DbService } from "./db.service";
import { DbDocumentObject } from "./documents.service";
import { AppDb, Utils } from "fakturnia-shared";
import { DbProduct } from "fakturnia-shared";

@Injectable({
    providedIn: 'root'
})
export class ProductsService implements OnDestroy {
 
    private _subscriptions:Subscription[] = [];
    private _products = new BehaviorSubject<DbProductObject>({});
    private _db:AppDb

    currentProducts = this._products.asObservable();

    constructor(
        private _dbService:DbService,
        private _dataSynchronizerService:DataSynchronizerService
        )
    {
            this._subscriptions.push(this._dbService.getDatabase().subscribe({
                next: (data)=> {
                    this._db = data
                }
            }))

            this._subscriptions.push(this._dataSynchronizerService.currentData.subscribe({
                next: (data) => {
                    if(!data) return
                    if(data.table != 'products') return
    
                    // Update
                    data.value.forEach((product: DbProduct) => {
                        this._products.value[<string>product._id] = product
                    });
    
                    // Push changes
                    this._products.next(this._products.value)
    
                    this._dataSynchronizerService.received()
                }
            }))
    }

    public browseProducts({ currentPage, pageSize, activeFilters, filters }): PaginatedDbProducts {

        let t1 = new Date().getTime();

        let fromIndex = (currentPage * pageSize) - pageSize;
        let toIndex = fromIndex + (pageSize - 1);

        let results: any = Object.entries(this._products.value)
            .map(x => x[1])

        if (filters.length > 0) {
            filters.forEach(filter => {
                results = results.filter(filter)
            })
        }

        results.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        activeFilters.forEach((element: any) => {

            let key = element[0];
            let value = element[1];

            let filterValue = value;
            if (typeof filterValue == 'string') { filterValue = filterValue.toLowerCase() }

            if (key == 'searchText') {
                results = results.filter(
                    (product: DbProduct) => {
                        let found = false;
                        let name = ''
                        let netPrice = product.netPrice;
                        let grossValue = product.grossValue;
                       
                        if (Utils.isNullOrEmpty(product)) return false;
                        if (!Utils.isNullOrEmpty(product.name)) { name = product.name.toLowerCase(); }

                        if(name.includes(filterValue)) found = true
                        return found; 
                    })
            }
        })

        if (toIndex > results.length - 1) {
            toIndex = results.length - 1;
        }

        const paginationResult: PaginatedDbProducts = {
            executionTime: new Date().getTime() - t1,
            currentPage: currentPage,
            pages: Math.ceil(results.length / pageSize),
            pageSize: pageSize,
            resultsCount: results.length,
            results: Utils.getObjectsInRange(results, fromIndex, toIndex),
        }

        return paginationResult

    }

    public getById(id) {
        const client = this._products.value[id]
        if (!client) {
            console.log(`[ProductsService]: Product with id: ${id} not found.`)
            return null
        }
        return client
    }

    public getAll(): Observable<any> {
        if(!this._db) return of(null)
        const s1 = new Date().getTime();
        const loader = this._db.transaction("r", this._db.products, () => {
            return this._db.products.orderBy('createdAt').reverse().toArray();
        }).then((result: DbProduct[]) => {
            const object: DbProductObject = {};
            // result = this._processDocuments(result)
            result.forEach(prod => {
                object[<string>prod._id] = prod;
            });
            return object;
        });

        return forkJoin([from(loader)]).pipe(
            map(([products]) => {
                // Update your subjects
                this._products.next(products);
                return { products };
            }),
            tap(() => console.log(`[Products Service]: Built in ${new Date().getTime() - s1}ms.`))
        );
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub=>sub.unsubscribe())
    }
}
export interface DbProductObject {
    [key: number]: DbProduct;
}

export interface PaginatedDbProducts {
    results: DbProduct[] | null
    resultsCount: number
    executionTime: number
    pages: number
    pageSize: number
    currentPage: number
}
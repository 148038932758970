<div class="relative flex flex-col w-full min-h-full max-h-full">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-gray-50 dark:bg-primary border-b dark:border-gray-800">
        <div class="text-2xl font-medium">
            Dostosuj dokument
        </div>
        <button mat-icon-button [matDialogClose]="undefined">
            <mat-icon class="dark:text-white" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>

    <!-- Content -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 overflow-y-scroll md:overflow-y-auto p-6 mb-14">
            <div class="grid grid-cols-6 gap-3 scrollable pr-3">
                <div class="col-span-6 md:col-span-3">
                    <h6 class="text-md">Pokaż / ukryj na formularzu</h6>

                    <div *ngFor="let field of generalToggleableFields">
                        <mat-checkbox [matTooltip]="generalFields[field]?.informations"
                            [checked]="generalFieldsToShow.has(field)"
                            (change)="toggleFieldVisibility('general',field,$event)" color="primary">
                            {{generalFields[field].text}}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="col-span-6 md:col-span-3">
                    <!-- <h6>Waluta</h6> -->
                    <mat-form-field class="w-full" subscriptSizing="dynamic">
                        <mat-label>Waluta</mat-label>
                        <mat-select [(ngModel)]="currency">
                            <mat-option *ngFor="let cur of currencies" [value]="cur.id">{{cur.id}} - {{cur.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t dark:border-gray-900 dark:bg-gray-900 z-10 bg-gray-50">
        <div class="flex items-end">
            <div class="ml-auto">
                <button mat-flat-button color="primary" (click)="saveChanges()">Zapisz</button>
                <button mat-flat-button (click)="close()" class="ml-2">Zamknij</button>
            </div>
        </div>
    </div>

</div>
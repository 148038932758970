import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, MatFormFieldModule, MatButtonModule, MatInputModule, MatDialogModule],
  selector: 'app-search-gus-company-dialog',
  templateUrl: './search-gus-company-dialog.component.html',
  styleUrls: ['./search-gus-company-dialog.component.scss']
})
export class SearchGusCompanyDialogComponent {

  companies = [];
  constructor(
    private dialog: MatDialogRef<SearchGusCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if (typeof data != 'undefined') {
        if(typeof data.companies != 'undefined') {
          this.companies = data.companies
        }
      }
  }

  close(action) {
    this.dialog.close({
      action: action,
      company: null
    })
  } 

  selectCompany(company) {
    this.dialog.close({
      action: true,
      company: company
    }) 
  }
}

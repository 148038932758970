import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { DocumentsService } from 'shared/services/documents.service';
import { InvoicesService } from 'shared/services/invoices.service';
import { SnackbarService } from 'shared/services/snackbar.service';
import { InvoiceGenerator } from 'fakturnia-shared';
import { Invoice } from 'fakturnia-shared';
import { DbDocument } from 'fakturnia-shared';
import { TemplateUtils } from 'fakturnia-shared';
import { Utils } from 'fakturnia-shared';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatButtonModule, FormsModule, MatDialogModule, MatIconModule],
  selector: 'app-print-many-dialog',
  templateUrl: './print-many-dialog.component.html',
  styleUrls: ['./print-many-dialog.component.scss']
})
export class PrintManyDialogComponent {

  private _subscriptions:Subscription[] = []

  rangeOptions: {month: string, monthNumber:number,  year: number, invoices: Invoice[]}[] = []
  invoiceTypes = TemplateUtils.documents

  selectedRange:{month: string, monthNumber:number,  year: number, invoices: Invoice[]} | null = null
  selectedType = ''

  private _invoices: DbDocument[] = []

  public processing = false

  constructor(
    private _documentsService:DocumentsService,
    private _invoicesService:InvoicesService,
    private _snackbarService:SnackbarService,
    private _dialog: MatDialogRef<PrintManyDialogComponent>,
    ) 
    {

    this._subscriptions.push(
      this._documentsService.currentDocuments.subscribe({
        next: (data) => {
          this._invoices = Object.values(data)
          this._groupInvoicesByYearAndMonth()
          this.selectedRange = this.rangeOptions[0]
        }
      })
    )

  }

  public print() {

    if(!this.selectedRange) return
    if(this.processing) return

    let filtered = this.selectedRange.invoices

    if(!Utils.isNullOrEmpty(this.selectedType) && this.selectedType != 'allRecorded') {
      filtered = filtered.filter(x=>x.documentType == this.selectedType)
    }

    else if (this.selectedType == 'allRecorded') {
      const excluded = ['FakturaProforma', 'FakturaProformaBezVAT', 'FakturaVATMarzaProforma']
      filtered = filtered.filter(x => !excluded.includes(x.documentType) )
    }
    
    if(filtered.length == 0) {
      this._snackbarService.showSnackBar('Brak faktur do wygenerowania.','info')
      return
    }

    this.processing = true
    InvoiceGenerator.createMultiple(`Faktury_${this.selectedRange.month}_${this.selectedRange.year}_fakturnia_pl`, filtered)
    this.processing = false
  }

  close() {
    this._dialog.close()
  }

  private _groupInvoicesByYearAndMonth() {
    this.rangeOptions = []
    this._invoices.forEach((document:DbDocument) => {

      const invoice = this._invoicesService.getById(document._id)
      if(!invoice) return
      // Only issued
      if(!document.isPaid) return
      if(Utils.isNullOrEmpty(invoice.issueDateHeader.value)) return

      const date = new Date(invoice.issueDateHeader.value)
      const year = date.getFullYear()
      const month = Utils.getMonthName(date.getMonth())
      const found = this.rangeOptions.find(x=>x.month == month && x.year == year)

      if(!found) this.rangeOptions.push({
          month: month,
          monthNumber: date.getMonth(),
          year: year,
          invoices: [invoice]
      })
      else {
        found.invoices.push(invoice)
      }

    })

    this.rangeOptions.sort((a,b)=> new Date(b.year,b.monthNumber,1).getTime() - new Date(a.year,a.monthNumber,1).getTime())

  }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { AnnouncementsListComponent } from 'shared/components/announcements/announcements-list.component';
import { InvoiceCardComponent } from 'shared/components/invoice-card/invoice-card.component';
import { PrintManyDialogComponent } from 'shared/dialogs/print-many-dialog/print-many-dialog.component';

@Component({
  standalone: true,
  imports: [CommonModule, RouterLink, AnnouncementsListComponent, InvoiceCardComponent, MatIconModule, MatButtonModule],
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  constructor(private _dialog: MatDialog){ }

  showPrintManyDialog() {
    this._dialog.open(PrintManyDialogComponent, {
      width: '500px',
      panelClass: 'fakturnia-responsive-dialog'
    })
  }
  
}

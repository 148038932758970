import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { SEOData } from 'shared/seo-data';
import { ApiService } from 'shared/services/api.service';
import { Utils } from 'fakturnia-shared';
import { NgxSeoService } from 'shared/modules/ngx-seo/ngx-seo.service';
import { CommonModule } from '@angular/common';
import { JoinUsComponent } from 'shared/components/join-us/join-us.component';
import { ReadMoreComponent } from 'shared/components/read-more/read-more.component';

@Component({
  standalone: true,
  imports: [CommonModule, RouterLink, JoinUsComponent, ReadMoreComponent],
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit, OnDestroy {

  subscriptions:Subscription[] = [];

  post;
  constructor(
    private route:ActivatedRoute,
    private apiService:ApiService,
    private ngxSeoService: NgxSeoService
    )
  {
    this.subscriptions.push(this.route.params.subscribe(
      (data:any)=> {
        if(data != null)
        {
          if(!Utils.isNullOrEmpty(data.id))
          {
            this.getPost(data.id);
          }
        }
      }
    ))
  }

  getPost(url)
  {
    const sub = this.apiService.getPost(url).subscribe(
      {
        next: (data:any)=> {
          if(data != null)
          {
            this.post = data;

            this.ngxSeoService.setSeo({
              title: data.title +' - '+ SEOData.DEFAULT_TITLE,
              meta: {
                description: data.shortContent,
              },
            });
          }
          
          sub.unsubscribe();
        },
        error: (data)=> {
          sub.unsubscribe();
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }
  ngOnInit(): void {
    
  }

}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError} from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private _authService: AuthService
  )  { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let headers = request.headers
    .set('x-access-token', this._authService.accessToken || '')
    .set('x-session-id', this._authService.sessionId || '');

    request = request.clone({
      withCredentials: true,
      headers: headers
    })

    return next.handle(request).pipe(
      catchError((error) => {
        throw new HttpErrorResponse(error);
      })
    );
  }

}
export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
}

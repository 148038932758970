import { Route } from '@angular/router';
import { AuthGuard } from 'shared/guards/auth.guard';
import { IsAuthenticatedAdminGuard } from 'shared/guards/is-authenticated-admin.guard';
import { IsNotAuthenticatedGuard } from 'shared/guards/is-not-authenticated.guard';
import { LayoutComponent } from 'shared/layout/layout.component';
import { SEOData } from 'shared/seo-data';
import { AccountComponent } from './account/account.component';
import { accountResolver } from './account/account.resolver';
import { ClientsComponent } from './account/clients/clients.component';
import { DashboardComponent } from './account/dashboard/dashboard.component';
import { DocumentsComponent } from './account/documents/documents.component';
import { IssueComponent } from './account/documents/issue/issue.component';
import { PreviewComponent } from './account/documents/preview/preview.component';
import { CreateProductComponent } from './account/products/create-product/create-product.component';
import { ProductsComponent } from './account/products/products.component';
import { AdminComponent } from './admin/admin.component';
import { AnnouncementsComponent } from './admin/announcements/announcements.component';
import { CreateOrUpdateAnnouncementComponent } from './admin/announcements/create-or-update-announcement/create-or-update-announcement.component';
import { BackupComponent } from './admin/backup/backup.component';
import { AdminDashboardComponent } from './admin/dashboard/dashboard.component';
import { ManageBlogComponent } from './admin/manage-blog/manage-blog.component';
import { ManagePostComponent } from './admin/manage-blog/manage-post/manage-post.component';
import { UserComponent } from './admin/users/user/user.component';
import { UsersComponent } from './admin/users/users.component';
import { AuthComponent } from './auth/auth.component';
import { ForgotPasswordPageComponent } from './auth/forgot-password-page/forgot-password-page.component';
import { RegisterPageComponent } from './auth/register-page/register.page.component';
import { AboutAppComponent } from './pages/about-app/about-app.component';
import { BlogComponent } from './pages/blog/blog.component';
import { PostComponent } from './pages/blog/post/post.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FakturaKoncowaComponent } from './pages/functionalities/faktura-koncowa/faktura-koncowa.component';
import { FakturaKorygujacaComponent } from './pages/functionalities/faktura-korygujaca/faktura-korygujaca.component';
import { FakturaVatMarzaComponent } from './pages/functionalities/faktura-vat-marza/faktura-vat-marza.component';
import { FakturaVatComponent } from './pages/functionalities/faktura-vat/faktura-vat.component';
import { FunctionalitiesComponent } from './pages/functionalities/functionalities.component';
import { InvoicePreviewComponent } from './pages/functionalities/invoice-preview/invoice-preview.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { RulesComponent } from './pages/rules/rules.component';
import { RemoteLoginComponent } from './tools/remote-login/remote-login.component';

export const appRoutes: Route[] = [
    {
        path: '',
        component: LayoutComponent,
        children: [
          {
            path: '', 
            data: { seo: SEOData.WELCOME }, 
            canActivate: [IsNotAuthenticatedGuard],
            loadChildren: () => import('./pages/welcome/welcome.routes'),
          },
          {
            path: 'o-aplikacji',
            component: AboutAppComponent,
            canActivate: [IsNotAuthenticatedGuard],
            // TODO SEO
            data: { seo: SEOData.ABOUT }
          },
         
          {
            path: 'kontakt',
            component: ContactComponent,
            data: { seo: SEOData.CONTACT }
          },
          {
            path: 'blog',
            component: BlogComponent,
            canActivate: [IsNotAuthenticatedGuard],
            data: { seo: SEOData.BLOG }
          },
          {
            path: 'funkcjonalnosci-aplikacji',
            component: FunctionalitiesComponent,
            canActivate: [IsNotAuthenticatedGuard],
            data: { seo: SEOData.FUNCTIONALITIES }
          },
          {
            path: 'funkcjonalnosci-aplikacji/podglad-faktury',
            component: InvoicePreviewComponent,
            canActivate: [IsNotAuthenticatedGuard],
            data: { seo: SEOData.INVOICE_PREVIEW }
          },
          {
            path: 'funkcjonalnosci-aplikacji/faktura-vat',
            component: FakturaVatComponent,
            canActivate: [IsNotAuthenticatedGuard],
            data: { seo: SEOData.FAKTURA_VAT }
          },
          {
            path: 'funkcjonalnosci-aplikacji/faktura-koncowa',
            component: FakturaKoncowaComponent,
            canActivate: [IsNotAuthenticatedGuard],
            data: { seo: SEOData.FAKTURA_KONCOWA }
          },
          {
            path: 'funkcjonalnosci-aplikacji/faktura-korygujaca',
            component: FakturaKorygujacaComponent,
            canActivate: [IsNotAuthenticatedGuard],
            data: { seo: SEOData.FAKTURA_KORYGUJACA }
          },
          {
            path: 'funkcjonalnosci-aplikacji/faktura-vat-marza',
            component: FakturaVatMarzaComponent,
            canActivate: [IsNotAuthenticatedGuard],
            data: { seo: SEOData.FAKTURA_VAT_MARZA }
            
          },
          {
            path: 'blog/:id',
            component: PostComponent,
            canActivate: [IsNotAuthenticatedGuard],
          },
          {
            path: 'logowanie',
            component: AuthComponent,
            canActivate: [IsNotAuthenticatedGuard],
            data: { seo: SEOData.LOGIN }
          },
          {
            path: 'rejestracja',
            component: RegisterPageComponent,
            canActivate: [IsNotAuthenticatedGuard],
            data: { seo: SEOData.REGISTER }
          },
          {
            path: 'przypomnij-haslo',
            component: ForgotPasswordPageComponent,
            canActivate: [IsNotAuthenticatedGuard],
            data: { seo: SEOData.FORGOT_PASSWORD }
          },
          {
            path: 'przypomnij-haslo/:token/:emailAddress',
            component: ForgotPasswordPageComponent,
            canActivate: [IsNotAuthenticatedGuard],
            data: { seo: SEOData.FORGOT_PASSWORD }
          },
          {
            path: 'konto',
            component: AccountComponent,
            resolve: { initialData: accountResolver },
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            children: [
              { path: '', redirectTo: 'dokumenty', pathMatch: 'full' },
              { path: 'pulpit', component: DashboardComponent, data: { seo: { title: "Pulpit"}} },
              
              { path: 'dokumenty', component: DocumentsComponent, data: { seo: { title: "Dokumenty"}} },
              { path: 'dokumenty/:id', component: IssueComponent, data: { seo: SEOData.ISSUE, footer: false } },
              { path: 'dokumenty/:id/podglad', component: PreviewComponent, data: { seo: SEOData.ISSUE, footer: false }},
              
              { path: 'produkty', component: ProductsComponent, data: { seo: {title: "Produkty" }} },
              { path: 'produkty/utworz',  component: CreateProductComponent, data: { seo: {title: "Dodaj produkt" }} },
              { path: 'produkty/:id', loadChildren: () => import('./account/products/product-preview/product-preview.routes'), data: { seo: {title: "Produkty" }} },
              
              { path: 'klienci', component: ClientsComponent, data: { seo: {title: "Klienci" }} },
              { path: 'klienci/:id', loadChildren: () => import('./account/clients/client-preview/client-preview.routes'), data: { seo: {title: "Klienci" }} },
              // { path: 'test', component: TestingComponent, data: { seo: {title: "Test" }} },
              { path: 'ustawienia', loadChildren: () => import('./account/settings/settings.routes'), data: { seo: {title: "Ustawienia" }} },
              { path: 'moje-konto', loadChildren: () => import('./account/my-account/my-account.routes'), data: { seo: {title: "Moje konto" }} }
            ]
          },
          {
            path: 'admin',
            component: AdminComponent,
            canActivate: [IsAuthenticatedAdminGuard],
            canActivateChild: [IsAuthenticatedAdminGuard],
            children: [
              { path: '', redirectTo: 'pulpit', pathMatch: 'full' },
              { path: 'pulpit', component: AdminDashboardComponent, data: { title: "Pulpit" } },
              { path: 'users', component: UsersComponent, data: { title: "Użytkownicy" } },
              { path: 'users/:id', component: UserComponent, data: { title: "Użytkownik" } },
              { path: 'announcements', component: AnnouncementsComponent, data: { title: "Ogłoszenia" } },
              { path: 'announcements/:id', component: CreateOrUpdateAnnouncementComponent, data: { title: "Ogłoszenie" } },
              { path: 'blog', component: ManageBlogComponent, data: { title: "Blog" } },
              { path: 'blog/post/add', component: ManagePostComponent, data: { title: "Post" } },
              { path: 'blog/post/edit/:id', component: ManagePostComponent, data: { title: "Post" } },
              { path: 'backup', component: BackupComponent, data: { title: "Backup" } },
        
            ]
          },
          {
            path: 'remote-login/:oneTimeToken',
            component: RemoteLoginComponent,
          },
          {
            path: 'regulamin',
            component: RulesComponent,
            // TODO SEO
            data: { seo: SEOData.CONTACT }
          },
          {
            path: 'polityka-prywatnosci',
            component: PrivacyPolicyComponent,
            // TODO SEO
            data: { seo: SEOData.CONTACT }
          },
        ]
      },
      {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full'
      }
]
<ng-container *ngIf="documents != null">
    <h2 class="text-3xl font-bold mb-3" *ngIf="showTitle">{{title}} <small class="text-secondary fw-normal">({{resultsCount}})</small></h2>

    <div class="bg-card rounded-xl" [ngClass]="{'shadow-lg': addBoxShadow }">
    
        <div class="grid gap-6 grid-cols-1 md:grid-cols-2 border-b p-4 items-center">
            <div>
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-icon matIconPrefix [svgIcon]="'tabler_outline:search'"></mat-icon>
                    <input type="text" matInput [formControl]="searchControl" placeholder="Szukaj dokumentu">
                    <button mat-icon-button matIconSuffix  (click)="reset()" *ngIf="searchControl.value.length > 0">
                        <mat-icon [svgIcon]="'tabler_outline:x'"></mat-icon>
                    </button>
                  
                    <mat-hint *ngIf="searchControl.value.length > 0" >
                        <span>Wyniki wyszukiwania dla frazy: </span> {{searchControl.value}}
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="justify-self-end">
                <pagination *ngIf="documents.length > 0" class="flex items-center" [currentPage]="currentPage"
                [totalPages]="allPages" (onPageChange)="onPageChange($event)"></pagination>
            </div>
        </div>
    
        <div class="grid gap-6 grid-cols-1 border-b p-2" *ngIf="documents.length > 0 && (showMarkAsPaidButton || showMarkAsTemplateButton || showIssueSimilarButton)">
            <div class="flex items-center space-x-4">
                <mat-icon [svgIcon]="'tabler_outline:corner-up-right'" class="ml-4" ></mat-icon>
    
                <button class="text-sm" *ngIf="showMarkAsPaidButton" [disabled]="selectedDocs.size <= 0" mat-flat-button color="primary" (click)="markAsPaidOrTemplate(true)">
                    <mat-icon>check</mat-icon>
                    <span class="ml-2 hidden md:inline-block">Oznacz jako wystawione</span> 
                </button>

                <button class="text-sm" *ngIf="showMarkAsTemplateButton" [disabled]="selectedDocs.size <= 0" mat-flat-button color="primary" (click)="markAsPaidOrTemplate(false)">
                    <mat-icon>edit</mat-icon>
                    <span class="ml-2 hidden md:inline-block">Oznacz jako szkic</span> 
                </button>

                <button class="text-sm" *ngIf="showIssueSimilarButton" [disabled]="selectedDocs.size != 1" mat-flat-button color="primary" (click)="issueSimilar()">
                    <mat-icon>content_copy</mat-icon>
                    <span class="ml-2 hidden md:inline-block">Wystaw podobny</span> 
                </button>
    
            </div>
        </div>
    
        <div class="flex bg-gray-50 dark:bg-slate-900 items-center border-b border-t px-4 content-center text-secondary py-2 font-semibold text-sm" *ngIf="documents.length > 0">
            <div class="flex items-center">
                <mat-checkbox class="-my-2" [matTooltip]="'Zaznacz wszystko'"  *ngIf="showSelectors" [disabled]="selection == 'single'" color="primary" (change)="selectAll($event)"></mat-checkbox>
            </div>
            <div class="w-full">Dokument</div>
            <div class="w-full text-end pr-4">Kwota</div>
        </div>
    
        <div class="flex items-center border-b p-8 content-center" *ngIf="documents.length == 0">
            <div class="text-secondary" *ngIf="searchControl.value.length > 0">Nie znaleziono dokumentów.</div>
            <div class="text-secondary" *ngIf="searchControl.value.length == 0 && showAddNewButton && resultsCount == 0">
    
                <button mat-button color="primary" [routerLink]="'/konto/dokumenty/wystaw'">
                    <mat-icon matPrefix [svgIcon]="'tabler_outline:plus'"></mat-icon>
                    <span class="ml-2">Dodaj pierwszy dokument</span>
                </button>
            </div>
        </div>

        <!-- Listing documents -->
        <div *ngFor="let document of documents" class="flex items-center border-b p-4 content-center hover:bg-slate-50 dark:hover:bg-slate-700">
            <div>
                <mat-checkbox *ngIf="showSelectors" [checked]="selectedDocs.has(document._id) ? true : false" color="primary"
                (change)="toggleSelect(document._id)"></mat-checkbox>
            </div>
            <div class="w-full cursor-pointer" (click)="navigateToDocument(document._id)">

                <div class="grid grid-cols-2">
                    <div>
                        <div>
                            <company-name [company]="document.buyer"></company-name>
                        </div>
                        <div class="text-xl text-secondary">
                            {{document.documentNumber}}
                        </div>
                       
                    </div>
                    <div>
                        <div class="text-end">
                            <span class="text-secondary text-sm">{{document.paymentStatus.value == 1 ? 'Do zapłaty' : 'Zapłacono'}}</span>    {{toDecimal(document.grossSum)}} <span class="ml-1 text-secondary text-xs">{{document.currency}}</span>
                        </div>
                        <div class="flex space-x-2 justify-end">
                            <mat-icon class="bg-sky-100 dark:bg-sky-900 p-1 rounded-xl text-primary"  *ngIf="document.isSent" matTooltip="Wysłano do klienta" [svgIcon]="'tabler_outline:mail-check'"></mat-icon>
                            <mat-icon class="bg-sky-100 dark:bg-sky-900 p-1 rounded-xl text-primary"  *ngIf="document.isDownloaded" matTooltip="Dokument został pobrany" [svgIcon]="'tabler_outline:download'"></mat-icon>
                            <mat-icon class="bg-sky-100 dark:bg-sky-900 p-1 rounded-xl text-primary"  *ngIf="document.isPrinted" matTooltip="Dokument został wydrukowany" [svgIcon]="'tabler_outline:printer'"></mat-icon>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>

        <div class="grid gap-6 grid-cols-1 p-4 items-center" *ngIf="documents.length > 0">
            <div class="justify-self-end">
                <pagination  class="flex items-center" [currentPage]="currentPage"
                [totalPages]="allPages" (onPageChange)="onPageChange($event)"></pagination>
            </div>
        </div>
    
    </div>
</ng-container>

<mat-spinner *ngIf="documents == null" color="primary" class="mx-auto"></mat-spinner>

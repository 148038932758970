import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { RegisterComponent } from 'shared/components/register/register.component';

@Component({
  standalone: true,
  selector: 'app-register',
  imports: [CommonModule, RegisterComponent, MatButtonModule, RouterLink, MatIconModule],
  templateUrl: './register.page.component.html',
  styleUrls: ['./register.page.component.scss']
})
export class RegisterPageComponent  {


}

<ng-container *ngIf="pagination.results != null">
    <h2 class="text-3xl font-bold mb-3" *ngIf="showTitle">{{title}} <small class="text-secondary fw-normal">({{pagination.resultsCount}})</small></h2>

    <div class="bg-card shadow-lg rounded-xl">
    
        <div class="grid gap-6 grid-cols-1 md:grid-cols-2 border-b  p-4 items-center">
            <div>
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-icon matIconPrefix [svgIcon]="'tabler_outline:search'"></mat-icon>
                    <input type="text" matInput [formControl]="searchControl" placeholder="Szukaj produkt">
                    <button mat-icon-button matIconSuffix  (click)="reset()" *ngIf="searchControl.value.length > 0">
                        <mat-icon [svgIcon]="'tabler_outline:x'"></mat-icon>
                    </button>
                  
                    <mat-hint *ngIf="searchControl.value.length > 0" >
                        <span>Wyniki wyszukiwania dla frazy: </span> {{searchControl.value}}
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="justify-self-end">
                <pagination  class="flex items-center" [currentPage]="pagination.currentPage"
                [totalPages]="pagination.pages" (onPageChange)="onPageChange($event)"></pagination>
            </div>
        </div>
    
        <div class="grid gap-6 grid-cols-1 border-b  p-4" *ngIf="pagination.results?.length > 0 && (showMarkAsPaidButton || showMarkAsTemplateButton || showIssueSimilarButton)">
            <div class="flex items-center">
                <mat-icon [svgIcon]="'tabler_outline:corner-up-right'"   class="tools-icon"></mat-icon>
            </div>
        </div>

        <!-- Header -->
        <div class="grid grid-cols-12 bg-gray-50 dark:bg-gray-900  items-center border-b border-t px-6 py-2 content-center text-secondary text-sm font-semibold" *ngIf="pagination.results?.length > 0">
            
            <div class="col-span-8 sm:col-span-5">
                Nazwa produktu
            </div>
            <div class="hidden sm:block sm:col-span-2">
                Cena netto
            </div>
            <div class="hidden sm:block sm:col-span-1">
                Ilość
            </div>
            <div class="col-span-4 sm:col-span-2">
                Wartość brutto
            </div>
            <div class="hidden sm:block sm:col-span-1">
                Stawka
            </div>

        </div>

        <!-- Body -->
        <div class="grid grid-cols-12 cursor-pointer hover:bg-slate-50 dark:hover:bg-slate-700 px-6 py-4 border-b " [routerLink]="product._id"  *ngFor="let product of pagination.results">
            <div class="col-span-8 sm:col-span-5 ">
                {{product.name}}
            </div>

            <div class="hidden sm:block sm:col-span-2">
                {{product.netPrice?.toFixed(2)}} <span class="ml-1 text-secondary text-xs">{{product.currency}}</span>
            </div>

            <div class="hidden sm:block sm:col-span-1">
                {{product.quantity}} <span class="text-secondary text-sm">x</span>
            </div>

            <div class="col-span-4 sm:col-span-2 ">
                {{product.grossValue?.toFixed(2)}} <span class="ml-1 text-secondary text-xs">{{product.currency}}</span>
            </div>

            <div class="hidden sm:block sm:col-span-1">
                {{product.vatRate}}<span *ngIf="product.vatRate >= 0">%</span>
            </div>
        </div>
    
        <div class="flex items-center border-b  p-8 content-center" *ngIf="pagination.results?.length == 0">
            <div class="text-secondary" *ngIf="searchControl.value.length > 0">Nie znaleziono klientów.</div>
            <div class="text-secondary" *ngIf="searchControl.value.length == 0 && showAddNewButton && resultsCount == 0">
    
                <button mat-button color="primary" [routerLink]="'/konto/dokumenty/wystaw'">
                    <mat-icon matPrefix [svgIcon]="'tabler_outline:plus'"></mat-icon>
                    <span class="ml-2">Dodaj pierwszego klienta</span>
                </button>
            </div>
        </div>

        <div class="grid gap-6 grid-cols-1 p-4 items-center" *ngIf="pagination?.results?.length > 0">
            <div class="justify-self-end">
                <pagination  class="flex items-center" [currentPage]="pagination.currentPage"
                [totalPages]="pagination.pages" (onPageChange)="onPageChange($event)"></pagination>
            </div>
        </div>
    
    </div>
</ng-container>

<mat-spinner *ngIf="pagination.results == null" color="primary" class="mx-auto"></mat-spinner>

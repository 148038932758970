import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { LowercaseEmailDirective } from 'shared/directives/lowercase-email.directive';
import { AuthService } from 'shared/services/auth.service';
import { emailValidator } from 'shared/validators/email.validator';
import { matchFieldsValidator } from 'shared/validators/match-fields.validator';

@Component({
  standalone: true,
  selector: 'register',
  imports: [ CommonModule, MatInputModule, ReactiveFormsModule, RouterLink, MatButtonModule, LowercaseEmailDirective, MatIconModule, MatFormFieldModule, MatCheckboxModule],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnDestroy {
  subscriptions: Subscription[] = []

  @Input('redirect') redirect = true

  registerForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required, emailValidator()]),
    emailAddressRepeat: new FormControl('', [Validators.required, emailValidator()]),
    password: new FormControl('',[ Validators.required, Validators.minLength(8)]),
    rules: new FormControl(true, [Validators.requiredTrue])
  }, { validators: matchFieldsValidator('emailAddress', 'emailAddressRepeat')  })
   
  showPassword = true
  public isProcessing = false
  constructor(
    private authService: AuthService,
    ) {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

  register() {

    if(!this.registerForm.valid) return;
    
    this.isProcessing = true
    this.authService.loginOrRegister(
      "register",
      this.registerForm.controls.emailAddress.value,
      this.registerForm.controls.password.value,
      this.redirect
    ).subscribe({
      next: (result) => {
        if(result != true) return
        this.isProcessing = false
      }
    })
    
  }
}

<div class="relative flex flex-col w-full min-h-full max-h-full">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-gray-50 dark:bg-gray-900 border-b">
        <div class="text-2xl font-medium">
            Wyślij przez e-mail
        </div>

        <button mat-icon-button [matDialogClose]="undefined">
            <mat-icon class="dark:text-white" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>

    </div>

    <!-- Content -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 overflow-y-scroll sm:overflow-y-auto p-6">
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Nowy odbiorca</mat-label>
                <input type="text" matInput [formControl]="newReceiver" placeholder="Wprowadź adres e-mail. "
                    (keyup.enter)="addReceiver()">
                    <mat-hint>Dodaj maksymalnie 3 odbiorców.</mat-hint>
                <button (click)="addReceiver()" matSuffix mat-icon-button>
                    <mat-icon [svgIcon]="'tabler_outline:plus'"></mat-icon>
                </button>
            </mat-form-field>

            <div class="text-lg mt-4">Odbiorcy ({{receivers.size}})</div>
            <div *ngFor="let emailAddress of availableReceivers">
                <mat-checkbox (change)="toggleReceiver($event, emailAddress)" [checked]="receivers.has(emailAddress)"
                    color="primary" class="mr-2">{{emailAddress}}</mat-checkbox>
            </div>

            <hr />

            <div class="text-lg">Dodatkowe opcje</div>
            <mat-checkbox [formControl]="sendCopyToUser" color="primary" class="mr-2">Wyślij kopie również do mnie</mat-checkbox>
        </div>
    </div>

    <!-- Footer -->
    <div  class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t z-10 bg-gray-50 dark:bg-gray-900">
        <div class="flex items-end">
            <div class="ml-auto">
                <button [disabled]="receivers.size == 0" mat-flat-button color="primary" (click)="sendEmail()">Wyślij wiadomość</button>
                <button mat-button (click)="close()" class="ml-2">Zamknij</button>
            </div>
        </div>
    </div>
</div>


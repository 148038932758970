export const API_HOST = 'https://sandbox.api.fakturnia.pl/api/'
// export const API_HOST = 'http://localhost:3000/api/'

export const settings = {
    version: require('../../package.json').version + 'dev',
    facebookUrl: 'https://facebook.com/fakturniapl',
    emailAddress: 'kontakt@fakturnia.pl',
    accessTokenKey: 'access_token',
    refreshTokenKey: 'refresh_token',
    sessionIdKey: 'session_id'
}
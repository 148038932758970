<div class="min-h-screen max-w-320 mx-auto py-8 px-4 sm:px-8 sm:py-16">
    <div class="grid grid-cols-2">
        <div class="col-span-2 md:col-span-1 max-w-100 w-full mx-auto">
            <h3 class="text-4xl font-medium">Logowanie</h3>
            <p class="mb-3">Zaloguj się na swoje konto <b>fakturnia.pl</b></p>

            <login></login>

            <div class="max-w-90 my-8">
                <button color="primary" mat-button [routerLink]="'/rejestracja'">
                    Nie mam jeszcze konta
                    <mat-icon iconPositionEnd>chevron_right</mat-icon>
                </button>
                <button color="primary" mat-button [routerLink]="'/przypomnij-haslo'">
                    Odzyskaj dostęp do konta
                    <mat-icon iconPositionEnd>chevron_right</mat-icon>
                </button>
            </div>

        </div>
        <div class="col-span-2 md:col-span-1 md:mt-12">
            <div class="max-w-100 w-full mx-auto">
                <h4 class="text-4xl mt-4">Nie masz jeszcze konta?</h4>
                <div class="p-4 mb-4 rounded-lg bg-primary text-white border-l-4 dark:border-primary-300 border-primary-900">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <i class="ti ti-shield-check-filled text-white text-3xl"></i>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-xl font-medium">Załóż bezpłatne konto w <b>28 sekund</b>.</h3>
                            <div class="my-2 text-base">
                                <ul role="list" class="list-disc pl-5 py-2 space-y-1">
                                    <li>Prowadź historię swoich dokumentów</li>
                                    <li>Wystawiaj <b>szybciej</b> dokumenty</li>
                                    <li>Korzystaj z wielu dodatkowych funkcji</li>
                                </ul>
                            </div>

                            <button [routerLink]="'/rejestracja'" class="w-full" mat-flat-button >
                                Załóż <b>darmowe</b> konto!
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="max-w-360 mx-auto px-4">
    <div class="mt-4">
        <div class="text-lg">Podgląd</div>
        <h3 class="text-3xl font-medium">{{invoice?.invoiceName}}</h3>
        <div class="flex items-center space-x-2">
            <div *ngIf="document?.isPaid" class="text-md bg-primary text-white px-2 rounded-xl">WYSTAWIONO</div>
            <div *ngIf="!document?.isPaid" class="text-md bg-card text-black dark:text-white rounded-xl px-2">SZKIC</div>
            <div *ngIf="document?.isSent" matTooltip="Wysłano do klienta" class="flex items-center bg-sky-100  rounded-xl text-primary text-md px-2">
                <mat-icon class="p-1 text-primary" [svgIcon]="'tabler_outline:mail-check'"></mat-icon>
                <span class="font-medium">WYSŁANO</span>
            </div>
            <div *ngIf="document?.isDownloaded" matTooltip="Dokument został pobrany" class="flex items-center bg-sky-100  rounded-xl text-primary text-md px-2">
                <mat-icon class="p-1 text-primary" [svgIcon]="'tabler_outline:download'"></mat-icon>
                <span class="font-medium">POBRANO</span>
            </div>
            <div *ngIf="document?.isPrinted" matTooltip="Dokument został wydrukowany" class="flex items-center bg-sky-100  rounded-xl text-primary text-md px-2">
                <mat-icon class="p-1 text-primary" [svgIcon]="'tabler_outline:printer'"></mat-icon>
                <span class="font-medium">WYDRUKOWANO</span>
            </div>
        </div>

    </div>
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-6 my-4">
        <div class="w-full sm:col-span-2">
            <mat-spinner class="mx-auto" *ngIf="!dataUrl"></mat-spinner>
            <div *ngIf="dataUrl" class="preview-container" >
                <ngx-extended-pdf-viewer 
                    [customToolbar]="customCheckboxZoomToolbar"
                    [base64Src]="dataUrl"
                    backgroundColor="#ffffff"
                    [height]="pdfPreviewHeight"
                    [showToolbar]="false"
                    [showSidebarButton]="false"
                    [showFindButton]="false"
                    [showPagingButtons]="false"
                    [showDrawEditor]="false"
                    [showStampEditor]="false"
                    [showTextEditor]="false"
                    [showZoomButtons]="false"
                    [showPresentationModeButton]="false"
                    [showOpenFileButton]="false"
                    [showPrintButton]="false"
                    [showDownloadButton]="false"
                    [showSecondaryToolbarButton]="false"
                    [showRotateButton]="undefined"
                    [showRotateCwButton]="false"
                    [showRotateCcwButton]="false"
                    [showHandToolButton]="false"
                    [showScrollingButton]="false"
                    [showSpreadButton]="false"
                    [showPropertiesButton]="false"
                    [zoom]="'auto'"
                    >
                    </ngx-extended-pdf-viewer>
                    <ng-template #customCheckboxZoomToolbar>
                         
                    </ng-template>
                <!-- <pdf-viewer src="previewData" style="display:block; width: 100%; height: 1500px;"></pdf-viewer> -->
                <!-- <iframe *ngIf="previewData" class="mx-auto" width="100%" height="1500" style="overflow:hidden; overflow-y:hidden;" [src]="previewData"></iframe> -->
            </div>
        </div>

        <div class="order-first sm:order-last">
            <div class="bg-card rounded-xl shadow-lg p-4 w-full sm:max-w-72">

                <div class="tool-link flex items-center" *ngIf="!document?.isPaid" (click)="markAsPaidOrTemplate(true)">
                    <mat-icon color="primary" svgIcon="tabler_outline:check"></mat-icon>
                    Oznacz jako wystawiony
                </div> 
                <div class="tool-link flex items-center" [routerLink]="'/konto/dokumenty/wystaw'">
                    <mat-icon color="primary" svgIcon="tabler_outline:plus"></mat-icon>
                    Nowa faktura
                </div>
                <div class="tool-link flex items-center" [routerLink]="'/konto/dokumenty/' + invoice?._id" [queryParams]="{ action: 'copy' }">
                    <i class="ti ti-copy"></i>
                    <mat-icon color="primary" svgIcon="tabler_outline:copy"></mat-icon>
                    Wystaw podobną fakturę
                </div>
                <!-- <div class="tool-link" [routerLink]="'/konto/dokumenty/' + invoice?._id"><i class="ti ti-bandage"></i>Wystaw korektę</div> -->
                
                <br/>

                <div class="tool-link flex items-center" [routerLink]="'/konto/dokumenty/' + invoice?._id">
                    <mat-icon color="primary" svgIcon="tabler_outline:pencil"></mat-icon>
                    Edytuj
                </div>
                <div class="tool-link flex items-center" *ngIf="document?.isPaid" (click)="markAsPaidOrTemplate(false)">
                    <mat-icon color="primary" svgIcon="tabler_outline:hammer"></mat-icon>
                    Zmień na szkic
                </div>
                
                <!-- <br/> -->
                <!-- <div class="tool-link" [routerLink]="'/konto/dokumenty/' + invoice?._id"><i class="ti ti-corner-up-right"></i>Wyślij fakturę emailem</div> -->
                
                <br/>

                <div class="tool-link flex items-center" (click)="showSendEmailDialog()">
                    <mat-icon color="primary" svgIcon="tabler_outline:mail-share"></mat-icon>
                    Wyślij przez e-mail
                </div>
                <div class="tool-link flex items-center" (click)="download()">
                    <mat-icon color="primary" svgIcon="tabler_outline:download"></mat-icon>
                    Pobierz
                </div>
                <div class="tool-link flex items-center" (click)="print()">
                    <mat-icon color="primary" svgIcon="tabler_outline:printer"></mat-icon>
                    Drukuj
                </div>
                
                <br/>

                <div class="tool-link flex items-center delete-action" (click)="confirmDeleteInvoice()">
                    <mat-icon color="warn" class="delete-action" svgIcon="tabler_outline:trash"></mat-icon>
                    Usuń
                </div>
            

               
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-lg-8">
            Aktywność
            <div class="text-3xl flex items-center">
                Historia dokumentu
            </div>
            <activity-logs [activityLogs]="activityLogs"></activity-logs>
        </div>
    </div>
</div>


import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription, finalize, take } from 'rxjs';
import { APIResponse, AppData, DbProduct, NotificationData, Utils, VatRateUtils } from 'fakturnia-shared';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProductsApiService } from 'shared/services/api/products.api.service';
import { DataSynchronizerService } from 'shared/services/data-synchronization.service';
import { ProductsService } from 'shared/services/products.service';
import { SynchronizationService } from 'shared/services/synchronization.service';
import { ToastService } from 'shared/services/toast.service';
import { DecimalFormatterDirective } from 'shared/directives/decimal-formatter.directive';
import { QuantityDirective } from 'shared/directives/quantity.directive';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'product-informations',
  standalone: true,
  imports: [CommonModule, DecimalFormatterDirective, QuantityDirective, RouterModule, ReactiveFormsModule, MatIconModule, MatSelectModule, MatTooltipModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatButtonModule],
  templateUrl: './product-informations.component.html',
  styleUrl: './product-informations.component.scss'
})
export class ProductInformationsComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = []
  private _id = null
  public product:DbProduct | null = null

  @Input() mode: 'CREATE' | 'UPDATE' = 'UPDATE'
  gtu = AppData.GTU
  measurementUnits = AppData.MeasurementUnits
  vatRates = AppData.VatRates
  currencies = AppData.Currencies

  // Field names must match
  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    // gtu: new FormControl(''),
    unitOfMeasure: new FormControl('szt.'),
    quantity: new FormControl(1),
    netPrice: new FormControl(0),
    netValue: new FormControl(0),
    grossValue: new FormControl(0),
    amountOfVat: new FormControl(0),
    currency: new FormControl('PLN'),
    vatRate: new FormControl<string | number>(23),
  })

  isProcessing = false

  constructor(
    private _productsService:ProductsService,
    private _productsApiService:ProductsApiService,
    private _route:ActivatedRoute,
    private _dataSynchronizerService:DataSynchronizerService,
    private _synchronizationService:SynchronizationService,
    private _toastService:ToastService,
    private _router:Router
  ) {

  }

  public create() {

    if(this.mode != 'CREATE') return
    if(this.form.invalid) return
    if(this.isProcessing) return
    this.isProcessing = true

    const formValue:any = this.form.value
    this._productsApiService.create(formValue)
    .pipe(take(1))
    .pipe(finalize(() => {
      this.isProcessing = false
    }))
    .subscribe({
      next: (response:APIResponse) => {
        if(response.success != true) {
          this._toastService.showToast(new NotificationData({
            title: 'Wystąpił błąd',
            status: 'warning',
            message: response.message,
          }))
          return
        }
    
        this._dataSynchronizerService.next('products',[response.data])
        this._synchronizationService.synchronize()
    
        this._toastService.showToast(new NotificationData({
          title: 'Informacja',
          status: 'info',
          message: 'Produkt został utworzony.',
        }))

        this._router.navigateByUrl(`/konto/produkty/${response.data._id}`)
      }
    })
  }

  public saveChanges() {

    if(this.mode != 'UPDATE') return
    if(this.form.invalid) return
    if(this.isProcessing) return
    this.isProcessing = true

    const formValue:any = cloneDeep(this.form.value)
    formValue.quantity = parseFloat(formValue.quantity.toString())
    this._productsApiService.update(this._id, formValue)

    .pipe(take(1))
    .pipe(finalize(() => {
      this.isProcessing = false
    }))
    .subscribe({
      next: (response:APIResponse) => {
        if(response.success != true) {
          this._toastService.showToast(new NotificationData({
            title: 'Wystąpił błąd',
            status: 'warning',
            message: response.message,
          }))
          return
        }
    
        this._dataSynchronizerService.next('products',[response.data])
        this._synchronizationService.synchronize()
    
        this._toastService.showToast(new NotificationData({
          title: 'Informacja',
          status: 'info',
          message: 'Dane produktu zostały zapisane.',
        }))
      }
    })
  }

  private _loadProduct() {

    if(!this._id) return
    this.product = this._productsService.getById(this._id)
    if(!this.product) return

    Object.keys(this.form.controls).forEach(key => {
      if(!this.product) return
      if(this.product[key] == null) return
      this.form.controls[key].setValue(this.product[key])
    })

  }

  ngOnInit(): void {

    if(this._route.parent) {
      this._subscriptions.push(this._route.parent.params.subscribe(params => {

        if(this.mode != 'UPDATE') return
        if (!Utils.isDefined(params, "id")) {
          return
        }

        this._id = params['id']
        this._loadProduct()
      }))
    }

    this._subscriptions.push(
      this.form.controls.vatRate.valueChanges.subscribe({
        next: (vatRate) => {
          this.calculateByNetPrice(this.form.controls.netPrice.value)
      }})
    )

    this._subscriptions.push(
      this.form.controls.quantity.valueChanges.subscribe({
        next: (quantity) => {
          this.calculateByNetPrice(this.form.controls.netPrice.value)
      }})
    )


  
  }

  public calculateByNetPrice(netPrice) {
    if(!netPrice) return
    if(Utils.isNullOrEmpty(netPrice)) return
    if(typeof netPrice == 'string') netPrice = parseFloat(netPrice)

    const quantity = this.form.controls.quantity.value || 0
    let vatRate = this.form.controls.vatRate.value || 0

    let netValue = 0
    let amountOfVat = 0
    let grossValue = 0

    netPrice = parseFloat((netPrice).toFixed(2));
    netValue = parseFloat((netPrice * quantity).toFixed(2));

    if (VatRateUtils.isNumberVatRate(vatRate)) {
      vatRate = parseFloat(vatRate.toString());
      amountOfVat = parseFloat((((netValue / 100) * vatRate)).toFixed(2));
      grossValue = parseFloat((netValue + amountOfVat).toFixed(2));
    } else {
      amountOfVat = 0
      grossValue = parseFloat((netValue).toFixed(2));
    }

    this.form.controls.netPrice.setValue(netPrice, { emitEvent: false})
    this.form.controls.netValue.setValue(netValue, { emitEvent: false})
    this.form.controls.grossValue.setValue(grossValue, { emitEvent: false})
    this.form.controls.amountOfVat.setValue(amountOfVat, { emitEvent: false})
  }

  public calculateByGrossValue(grossValue) {

    if(!grossValue) return
    if(Utils.isNullOrEmpty(grossValue)) return
    if(typeof grossValue == 'string') grossValue = parseFloat(grossValue)

    const quantity = this.form.controls.quantity.value || 0
    // Konwersja stawki VAT na liczbę lub ustawienie 0, jeśli jest typu string
    let vatRate = this.form.controls.vatRate.value || 0
    let vatRateValue = 0
    if(VatRateUtils.isNumberVatRate(vatRate)) {
        vatRate = parseFloat(vatRate.toString());
        vatRateValue = vatRate
    } 

    // Sprawdzenie, czy stawka VAT jest prawidłowa
    if (isNaN(vatRateValue) || vatRateValue < 0) {
      vatRateValue = 0; // Ustawienie stawki VAT na 0, jeśli nie jest prawidłowa
    }
    
    let netPrice = 0
    let netValue = 0
    let amountOfVat = 0

    // Obliczanie wartości netto
    netValue = parseFloat((grossValue / (1 + vatRateValue / 100)).toFixed(2));

    // Obliczanie wartości podatku VAT
    amountOfVat = parseFloat((grossValue - netValue).toFixed(2));

    netPrice = parseFloat((netValue / quantity).toFixed(2));

    this.form.controls.netValue.setValue(netValue, { emitEvent: false})
    this.form.controls.netPrice.setValue(netPrice, { emitEvent: false})
    this.form.controls.amountOfVat.setValue(amountOfVat, { emitEvent: false})
    this.form.controls.grossValue.setValue(grossValue, { emitEvent: false})
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyNameComponent } from '../company-name/company-name.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { PaginationComponent } from '../pagination/pagination.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClientsService, PaginatedDbClients } from 'shared/services/clients.service';
import { debounce } from 'lodash-es';
import { DataSynchronizerService } from 'shared/services/data-synchronization.service';

@Component({
  selector: 'clients-table',
  standalone: true,
  imports: [CommonModule, CompanyNameComponent, RouterLink, MatTooltipModule, MatCheckboxModule, MatButtonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatIconModule, PaginationComponent],
  templateUrl: './clients-table.component.html',
  styleUrl: './clients-table.component.scss'
})
export class ClientsTableComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  activeFilters = new Set<any>();
  bsActiveFilters = new BehaviorSubject<any>(this.activeFilters);
  currentActiveFilters = this.bsActiveFilters.asObservable();
  
  searchControl = new FormControl('')

  pagination:PaginatedDbClients = {
    pageSize: 10,
    pages: 0,
    currentPage: 1,
    results: null,
    resultsCount: 0,
    executionTime: 0
  }

  @Input() showTitle = true
  @Input() title = 'Baza klientów'
  @Input('filters') filters:any[] = []
  
  constructor(
    private _clientsService: ClientsService,
    private _dataSynchronizerService: DataSynchronizerService
    ) {

    this._browse = debounce(this._browse,10)
  }



  public search(value)
  {
    this.pagination.currentPage = 1;
    this._updateFilterSet('searchText',value)
    this._browse();
  }

  public onPageChange(page)
  {
    this.pagination.currentPage = page;
    this._browse();
  }

  private _browse() {
    this.pagination = this._clientsService.browseClients({
      currentPage: this.pagination.currentPage,
      pageSize: this.pagination.pageSize,
      filters: this.filters,
      activeFilters: this.activeFilters,
    })
  }

  private _updateFilterSet(key, value) {
    if (!value || value == '') {
      // Jeśli wartość jest pustym ciągiem lub białym znakiem, usuń filtr z zestawu
      for (let filter of this.activeFilters) {
        if (filter[0] === key) {
          this.activeFilters.delete(filter);
          // this.searchResults = Object.entries(this.databaseService.data.value.documents).filter((document:[any,DbDocument])=> !document[1].isDeleted).map(x=>x[1]);
          this.bsActiveFilters.next(this.activeFilters)
          return;
        }
      }
      return;
    }
    // Sprawdzenie, czy dany klucz już istnieje w secie
    for (let filter of this.activeFilters) {
      if (filter[0] === key) {
        // Jeśli klucz istnieje, zaktualizuj wartość filtra
        // Jezeli nowa wartość filtra jest inna od obecnego
        if(filter[1] != value) {
          // Jezeli jest stringiem
          if(typeof filter[1] == 'string')
          {
            // Zwieksz zakres tylko jezeli wartosc jest mniejsza
            //sprawdz czy jest mniejsza (zwieksz zakres)
            if(filter[1].length > value.length)
            {
              // this.searchResults = Object.entries(this.databaseService.data.value.documents).filter((document:[any,DbDocument])=> !document[1].isDeleted).map(x=>x[1]);
            }

            filter[1] = value;
          }
          // Jezeli jest pozostałym typem
          else {
            filter[1] = value;
            // this.searchResults = Object.entries(this.databaseService.data.value.documents).filter((document:[any,DbDocument])=> !document[1].isDeleted).map(x=>x[1]);
          }
        } 

        
        this.bsActiveFilters.next(this.activeFilters)
        return;
      }
    }
    // Jeśli klucz nie istnieje, dodaj nowy filtr
    this.activeFilters.add([key, value]);
    this.bsActiveFilters.next(this.activeFilters)
  }

  ngOnInit(): void {

    this._subscriptions.push(
      this.searchControl.valueChanges.subscribe({
        next: (value) => {
          this.search(value)
        }
      })
    )

    this._subscriptions.push(this._dataSynchronizerService.currentData.subscribe({
      next: (data)=>{
        if(data == null) return
        if(data.table == 'documents') this._browse()
      }
    }))

    this._subscriptions.push(this.currentActiveFilters.subscribe({
      next:  (data)=>
        {
          this.activeFilters = data;
          setTimeout( async ()=>{
            this._browse();
          })
        }
      }
    ))

    this._browse();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }
}
